import React, { useEffect, useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import { commoncolumns } from "./common-columns";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { Image, message } from "antd";
import VaultModal from "./vault-modal";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { PlusOutlined } from "@ant-design/icons";

interface VaultTableProps {
  addApi: string;
  getApi: string;
  updateApi: string;
  publishApi: string;
  vaultType: string;
  isPagenation?: boolean;
}
const VaultTable: React.FC<VaultTableProps> = ({
  addApi,
  getApi,
  updateApi,
  publishApi,
  vaultType,
  isPagenation = true,
}) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState({});
  const fetchData = async () => {
    try {
      setLoading(true);
      const vaultRes = await apiRequest(getApi);
      if (vaultRes?.status === 200) {
        const tableData =
          
          vaultType === "Note"
            ? vaultRes?.data?.notes
            : vaultType === "Mnemonic"
            ? vaultRes?.data?.Mnemonics
            : vaultRes?.data?.[vaultType];
        setTableData(tableData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response?.data?.message || error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handlePublish = async ({ id }, isFreeForMarketing=false) => {
    try {
      const res = await apiRequest(publishApi, {isFreeForMarketing}, [id]);
      if (res?.status === 200) {
        message.success(res?.data?.message);
        fetchData();
      }
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
    }
  };
  const formatedData = tableData?.map((item, i) => {
    return {
      key: i + 1,
      ...item,
      _id: item?._id,
      desc:
        item?.desc?.length > 10 ? `${item?.desc.slice(0, 10)}...` : item?.desc,
      expandData: item?.desc,
      id: item?._id,
      isPublished: {
        isPublished: item?.isPublished,
        id: item?._id,
      },
      isFreeForMarketing: {
        isFreeForMarketing: item?.isFreeForMarketing,
        id: item?._id,
      },
    };
  });
  const handleEdit = (data) => {
    setEditData(data);
    setIsEditMode(true);
    setOpenModal(true);
  };
  const formatedColumns = commoncolumns?.map((item: any) => {
    switch (item?.dataIndex) {
      case "_id":
        return {
          ...item,
          render: (text: any, record: any) => {
            return <>{text}</>;
          },
        };
      case "thumbnailImageUrl":
        return {
          ...item,
          render: (pictureUrl, record:any) => {
              return (
                <Image
                  width={50}
                  height={50}
                  className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                  src={record?.thumbnailImageUrl || record?.thumbnailUrl}
                />
              );
          },
        };
      case "isFreeForMarketing":
        return {
          ...item,
          render: (text: any, record: any) => (
            <GlobalPublishButton
              onChange={() => handlePublish(text,true)}
              isActive={text?.isFreeForMarketing}
            />
          ),
        };
      case "isPublished":
        return {
          ...item,
          render: (text: any, record: any) => (
            <GlobalPublishButton
              onChange={() => handlePublish(text)}
              isActive={text?.isPublished}
            />
          ),
        };
      case "action":
        return {
          ...item,
          render: (text, record) => (
            <div className="flex gap-4 justify-center">
              <div onClick={() => handleEdit(record)}>
                <EditIcon color="primary" size="w-6 h-6"/>
              </div>
              <a
                rel="noreferrer"
                href={`${record?.pdfUrl || record?.imageUrl || record?.videoUrl}`}
                target="_blank"
              >
                <EyeIcon size="w-6 h-6" />
              </a>
            </div>
          ),
        };
      // Add more cases as needed
      default:
        return item;
    }
  });
  return (
    <div>
      <div className="flex justify-end">
        <CustomButton
          onClick={() => {
            setOpenModal(true);
            setIsEditMode(false);
          }}
          text={`Add New ${vaultType}`}
          icon={<PlusOutlined />}
        />
      </div>
      <GlobalTable
        tableName={vaultType}
        columns={formatedColumns}
        data={formatedData}
        loading={loading}
      />
      <VaultModal
        openModal={openModal}
        isPagenation={isPagenation}
        setOpenModal={setOpenModal}
        setIsEditMode={setIsEditMode}
        isEditMode={isEditMode}
        vaultType={vaultType}
        addApi={addApi}
        updateApi={updateApi}
        fetchData={fetchData}
        editData={editData}
      />
    </div>
  );
};

export default VaultTable;
