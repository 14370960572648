import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// import { getDistintTags } from "../../../APIs/PlanAPI";
// import { AddDeck, EditDeck } from "../../../APIs/DeckAPI";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { Select } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { DeckAPI } from "../../../../(apis)/(shared-apis)/academics/decks-api";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../../shared-components/ui/CustomButtons";
import { Switcher } from "../../../../shared-components/ui/CustomForm";
import { DeckInterface } from "../interface";
import { initialErrors } from "../utils";
import CustomDropDown from "shared-components/ui/CustomDropDown";

const DeckAddModal = ({
  deckGroupName,
  ShowModel,
  setShowModel,
  GetDecks,
  categoryName,
  mode,
  prepopulateData,
  setMode,
  setPopulated,
}) => {
  const [poped, setPoped] = useState(false);
  const [scoringPolicy, setScoringPolicy] = useState<any[]>([]);
  const [policyState, setPolicyState] = useState<any>({
    questionStart: 0,
    negativeMarking: 1,
  });

  const [formData, setFormData] = useState<DeckInterface>({
    deckCategory: categoryName,
    deckGroup: "Select DeckGroup",
    deckName: "",
    deckLogo: "",
    deckInstructions: "",
    isTutorModeFree: false,
    timedTestMode: false,
    timedTestMinutes: 0,
    note: "",
    premiumTags: [],
    isPublished: false,
    isMock: false,
    metadata: {
      entity: process.env.REACT_APP_ENTITY.replace("-", " "),
      category: process.env.REACT_APP_CATEGORY,
    },
    allowWithLogin: false,
    tagType: "",
    difficulty: "",
  });

  useEffect(() => {
    if (mode === "edit") {
      setFormData({
        deckName: prepopulateData.deckName,
        deckLogo: prepopulateData.deckLogo,
        deckInstructions: prepopulateData.deckInstructions,
        isTutorModeFree: prepopulateData.isTutorModeFree,
        timedTestMode: prepopulateData.timedTestMode,
        timedTestMinutes: prepopulateData.timedTestMinutes,
        note: prepopulateData.note,
        premiumTags: prepopulateData.premiumTags,
        isPublished: prepopulateData.isPublished,
        isMock: prepopulateData.isMock,
        metadata: {
          entity:
            prepopulateData?.metadata?.entity ||
            process.env.REACT_APP_ENTITY.replace("-", " "),
          category:
            prepopulateData?.metadata?.category ||
            process.env.REACT_APP_CATEGORY,
        },
        allowWithLogin: prepopulateData.allowWithLogin,
        tagType: prepopulateData.tagType,
        difficulty: prepopulateData.difficulty,
      });

      setScoringPolicy(prepopulateData.scoringPolicy);
      setPoped(true);
    } else {
      setFormData({
        deckName: "",
        deckLogo: "",
        deckInstructions: "",
        isTutorModeFree: false,
        timedTestMode: false,
        timedTestMinutes: 0,
        note: "",
        premiumTags: [],
        isPublished: false,
        isMock: false,
        metadata: {
          entity: process.env.REACT_APP_ENTITY.replace("-", " "),
          category: process.env.REACT_APP_CATEGORY,
        },
        allowWithLogin: false,
        tagType: "",
        difficulty: "",
      });
      setScoringPolicy([]);
      setPoped(false);
    }
  }, [mode]);

  const [disabled, setDisabled] = useState(false);
  const [accessTag, setAccessTag] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await apiRequest("getBundleTags");
        setAccessTag(response?.data?.tags);
      } catch (err) {
        console.error("Error fetching tags:", err);
      }
    };

    fetchTags();
  }, []);

  const [errors, setErrors] = useState(initialErrors);

  const AddPremiumTag = (tag: string) => {
    if (formData.premiumTags.includes(tag)) {
      return;
    }
    setFormData({
      ...formData,
      premiumTags: [...formData.premiumTags, tag],
    });
  };

  const RemovePremiumTag = (tag: string) => {
    setFormData({
      ...formData,
      premiumTags: formData.premiumTags.filter((item) => item !== tag),
    });
  };

  const UploadImageAndConvertTobase64 = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFormData({
        ...formData,
        deckLogo: reader.result as string,
      });
    };
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    if (name === "isPublished") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (name === "timedTestMode") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const CheckValidity = () => {
    const newErrors = { ...errors };
    let isError = false;
    if (!formData.deckName) {
      newErrors.deckNameError.error = true;
      newErrors.deckNameError.message = "Deck Name is required";
      isError = true;
    } else {
      newErrors.deckNameError.error = false;
      newErrors.deckNameError.message = "";
    }
    if (!formData.deckLogo) {
      newErrors.deckLogoError.error = true;
      newErrors.deckLogoError.message = "Deck Logo is required";
      isError = true;
    } else {
      newErrors.deckLogoError.error = false;
      newErrors.deckLogoError.message = "";
    }
    setErrors(newErrors);
    return isError;
  };

  const Submit = async () => {
    CheckValidity();
    if (CheckValidity()) {
      return;
    }
    //If mock is selected and no timed test mode is selected and timed test minutes is 0
    if (
      formData.isMock &&
      formData.timedTestMode &&
      formData.timedTestMinutes === 0
    ) {
      toast.error("Please Enter Timed Test Minutes");
      return;
    }

    if (formData.timedTestMode) {
      if (formData.timedTestMinutes <= 0) {
        toast.error("Please Enter Valid Timed Test Minutes");
        return;
      }
    }

    setDisabled(true);

    const data = {
      deckCategory: categoryName,
      deckGroup: deckGroupName,
      deckName: formData.deckName,
      deckLogo: formData.deckLogo,
      deckInstructions: formData.deckInstructions,
      isTutorModeFree: formData.isTutorModeFree,
      scoringPolicy: scoringPolicy,
      timedTestMode: formData.timedTestMode,
      timedTestMinutes: formData.timedTestMinutes,
      note: formData.note,
      premiumTags: formData.premiumTags,
      isPublished: formData.isPublished,
      isMock: formData.isMock,
      metadata: {
        entity: process.env.REACT_APP_ENTITY.replace("-", " "),
        category: process.env.REACT_APP_CATEGORY,
      },
      allowWithLogin: formData.allowWithLogin,
      tagType: formData.tagType,
      difficulty: formData.difficulty,
    };
    console.log(data);

    const Response = await apiRequest("addDeck", data);
    if (Response.data.success) {
      toast.success(Response.data.message);
      setShowModel(false);
      setFormData({
        deckName: "",
        deckLogo: "",
        deckInstructions: "",
        isTutorModeFree: false,
        timedTestMode: false,
        timedTestMinutes: 0,
        note: "",
        premiumTags: [],
        isPublished: false,
        isMock: false,
        metadata: {
          entity: process.env.REACT_APP_ENTITY.replace("-", " "),
          category: process.env.REACT_APP_CATEGORY,
        },
        allowWithLogin: formData.allowWithLogin,
        tagType: "",
        difficulty: "",
      });
      GetDecks();
    } else {
      toast.error(Response.data.message);
    }
    setDisabled(false);
    setMode("add");
    setPopulated(null);
  };

  const AddPolicy = () => {
    const { questionStart, questionEnd, negativeMarking } = policyState;

    if (parseInt(questionStart) <= 0 || parseInt(questionEnd) <= 0) {
      toast.error("Range cannot be negative");
      return;
    }

    if (parseInt(questionEnd) <= parseInt(questionStart)) {
      toast.error("End Range cannot be less than Start Range");
      return;
    }

    const overlappingPolicy = scoringPolicy.filter((policy) => {
      if (
        parseInt(policy.questionStart) <= parseInt(questionStart) &&
        parseInt(policy.questionEnd) >= parseInt(questionStart)
      ) {
        return true;
      }
      if (
        parseInt(policy.questionStart) <= parseInt(questionEnd) &&
        parseInt(policy.questionEnd) >= parseInt(questionEnd)
      ) {
        return true;
      }
      if (
        parseInt(policy.questionStart) >= parseInt(questionStart) &&
        parseInt(policy.questionEnd) <= parseInt(questionEnd)
      ) {
        return true;
      }
      return false;
    });

    if (overlappingPolicy.length > 0) {
      toast.error("Overlapping Policy");
      return;
    }

    setScoringPolicy([
      ...scoringPolicy,
      {
        questionStart: parseInt(questionStart),
        questionEnd: parseInt(questionEnd),
        negativeMarking: parseInt(negativeMarking),
      },
    ]);
  };

  const RemovePolicy = (index: number) => {
    const newScoringPolicy = scoringPolicy.filter((_, i) => i !== index);
    setScoringPolicy(newScoringPolicy);
  };

  const HandlePolicyState = (e: any) => {
    const { name, value, type } = e.target;

    if (type === "number" && value < 0) {
      toast.error("Range cannot be negative");
      return;
    }

    setPolicyState((prevState: any) => ({
      ...prevState,
      [name]: type === "checkbox" ? e.target.checked : value,
    }));
  };

  const EditSubmission = async () => {
    CheckValidity();
    if (CheckValidity()) {
      return;
    }

    if (formData.timedTestMode) {
      if (formData.timedTestMinutes <= 0) {
        toast.error("Please Enter Valid Timed Test Minutes");
        return;
      }
    }

    setDisabled(true);

    const data = {
      deckCategory: categoryName,
      deckGroup: deckGroupName,
      deckName: formData.deckName,
      deckLogo: formData.deckLogo,
      deckInstructions: formData.deckInstructions,
      isTutorModeFree: formData.isTutorModeFree,
      scoringPolicy: scoringPolicy,
      timedTestMode: formData.timedTestMode,
      timedTestMinutes: formData.timedTestMinutes,
      note: formData.note,
      premiumTags: formData.premiumTags,
      isPublished: formData.isPublished,
      isMock: formData.isMock,
      allowWithLogin: formData.allowWithLogin,
      metadata: {
        entity: formData.metadata.entity,
        category: formData.metadata.category,
      },
      tagType: formData.tagType,
      difficulty: formData.difficulty,
    };
    const Response = await DeckAPI("editDeck", "", {
      payload: data,
      id: prepopulateData._id,
    });
    if (Response.data.success) {
      toast.success(Response.data.message);
      setShowModel(false);
      setFormData({
        deckName: "",
        deckLogo: "",
        deckInstructions: "",
        isTutorModeFree: false,
        timedTestMode: false,
        timedTestMinutes: 0,
        note: "",
        premiumTags: [],
        isPublished: false,
        isMock: false,
        allowWithLogin: formData.allowWithLogin,
        metadata: {
          entity: formData.metadata.entity,
          category: formData.metadata.category,
        },
        tagType: "",
        difficulty: "",
      });

      GetDecks();
      setShowModel(false);
      setMode("add");
      setPopulated(null);
    } else {
      toast.error(Response.data.message);
    }
    setDisabled(false);
  };

  // Options for the dropdowns
  const tagTypeOptions = [
    { label: "High Yield", value: "high_yield" },
    { label: "Low Yield", value: "low_yield" },
  ];

  const difficultyOptions = [
    { label: "Easy", value: "easy" },
    { label: "Medium", value: "medium" },
    { label: "Hard", value: "hard" },
  ];

  return (
    <>
      <Toaster />
      {((mode === "add" && !poped) || (mode === "edit" && poped)) && (
        <div
          id="createProductModal"
          tabIndex={-1}
          aria-hidden="true"
          className={`${
            ShowModel ? "" : "hidden"
          } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40
            `}
        >
          <div
            className="relative w-full max-w-2xl max-h-full p-8 
          "
          >
            <div className="relative p-4 bg-white rounded-lg shadow overflow-y-auto ">
              <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                <h3 className="text-lg font-semibold text-gray-900 ">
                  {mode === "add" ? "Add New Deck" : "Edit Deck"}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-target="createProductModal"
                  data-modal-toggle="createProductModal"
                  onClick={() => {
                    setShowModel(false);
                    setMode("add");
                    setPopulated(null);
                    setFormData({
                      deckName: "",
                      deckLogo: "",
                      deckInstructions: "",
                      isTutorModeFree: false,
                      timedTestMode: false,
                      timedTestMinutes: 0,
                      note: "",
                      premiumTags: [],
                      isPublished: false,
                      isMock: false,
                      metadata: {
                        entity: process.env.REACT_APP_ENTITY.replace("-", " "),
                        category: process.env.REACT_APP_CATEGORY,
                      },
                      allowWithLogin: false,
                      tagType: "",
                      difficulty: "",
                    });
                  }}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="space-y-5 mt-3">
                <div>
                  <label className="block text-gray-900 ">
                    <span>Deck Name *</span>
                  </label>
                  <input
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                    type="text"
                    placeholder="Enter Deck Name"
                    name="deckName"
                    onChange={handleInputChange}
                    value={formData.deckName}
                  />
                  {errors.deckNameError.error && (
                    <span className="text-xs text-red-500">
                      {errors.deckNameError.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className="block text-gray-900 ">
                    <span>Deck Instructions</span>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={formData.deckInstructions}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        deckInstructions: value,
                      });
                    }}
                  />
                </div>

                <div>
                  <label className="block text-gray-900 ">
                    <span>Allowed For:</span>
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {formData.premiumTags.map((tag, index) => (
                      <div className="flex flex-row justify-between items-center bg-rose-500 rounded-full px-2 py-1 text-xs text-white my-1 gap-3">
                        <span key={index}>{tag}</span>
                        <button
                          onClick={() => {
                            RemovePremiumTag(tag);
                          }}
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center gap-2">
                    <Select
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent  font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                      placeholder="Select Premium Tag"
                      onSelect={(value) => {
                        AddPremiumTag(value);
                      }}
                    >
                      <Select.Option value={"Free Trial"}>
                        Free Trial
                      </Select.Option>
                      {accessTag?.map((tag, index) => {
                        if (!formData?.premiumTags?.includes(tag)) {
                          return (
                            <Select.Option key={index} value={tag}>
                              {tag}
                            </Select.Option>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  </div>
                </div>

                <div className="flex justify-between items-center gap-4">
                  <div className="flex flex-row flex-start items-center gap-2">
                    <div className="flex flex-row justify-between items-center gap-2">
                      <div className="flex items-center space-x-2">
                        <label className="flex items-center justify-center cursor-pointer">
                          Allow Without Login
                        </label>
                        <Switcher
                          isActive={undefined}
                          for={"allowWithLogin"}
                          onChange={() => {
                            setFormData({
                              ...formData,
                              allowWithLogin: !formData.allowWithLogin,
                            });
                          }}
                          togglevalue={formData.allowWithLogin}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                  {!formData.isMock && (
                    <div className="flex flex-row flex-start items-center gap-2">
                      <div className="flex items-center space-x-2">
                        <label className="flex items-center justify-center cursor-pointer">
                          <span className="mr-2">Is Tutor Mode Free?</span>
                          <Switcher
                            isActive={undefined}
                            for={"isTutorModeFree"}
                            onChange={() => {
                              setFormData({
                                ...formData,
                                isTutorModeFree: !formData.isTutorModeFree,
                              });
                            }}
                            togglevalue={formData.isTutorModeFree}
                            size="small"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-center space-x-2">
                  <label className="flex items-center justify-center cursor-pointer">
                    <span className="mr-2">Is Mock</span>
                    <Switcher
                      isActive={undefined}
                      for={"isMock"}
                      onChange={() => {
                        setFormData({
                          ...formData,
                          isMock: !formData.isMock,
                        });
                      }}
                      togglevalue={formData.isMock}
                      size="small"
                    />
                  </label>
                </div>

                <div className="flex flex-row justify-between items-center gap-2">
                  <div className="flex items-center space-x-2">
                    <label className="flex items-center justify-center cursor-pointer">
                      <span className="mr-2">Enable Timed Test Mode</span>
                      <Switcher
                        isActive={undefined}
                        for={"timedTestMode"}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            timedTestMode: !formData.timedTestMode,
                          });
                        }}
                        togglevalue={formData.timedTestMode}
                        size="small"
                      />
                    </label>
                  </div>
                  {formData.timedTestMode && (
                    <>
                      <div className="flex items-center gap-2">
                        <span>Timed Test Minutes</span>
                        <input
                          className="w-20 rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                          type="number"
                          name="timedTestMinutes"
                          onChange={handleInputChange}
                          value={formData.timedTestMinutes}
                        />
                      </div>
                    </>
                  )}
                </div>

                {/* Add Scoring Policy Here */}
                <div className="flex flex-col gap-1">
                  <span className="mr-2">Add Scoring Policy</span>
                  <div className="flex flex-col gap-1 items-center space-x-2 mt-2">
                    <div className="flex flex-row flex-wrap w-full gap-2 items-center justify-between">
                      <span>Range</span>
                      <input
                        className="rounded border-[1.5px] border-stroke bg-transparent py-1 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                        type="number"
                        name="questionStart"
                        placeholder="Start"
                        value={policyState.questionStart}
                        onChange={HandlePolicyState}
                      />
                      <input
                        className="rounded border-[1.5px] border-stroke bg-transparent py-1 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                        type="number"
                        name="questionEnd"
                        placeholder="End"
                        onChange={HandlePolicyState}
                        //   disabled={policyState.questionStart === 0 || policyState.questionStart === ''}
                      />
                      <div className="flex justify-between items-center space-x-2">
                        <input
                          type="number"
                          className="rounded border-[1.5px] border-stroke bg-transparent py-1 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                          name="negativeMarking"
                          placeholder="Negative Marking"
                          onChange={HandlePolicyState}
                          // disabled={policyState.questionStart === 0 || policyState.questionStart === ''}
                        />
                        <button
                          className="p-1 px-2 text-sm text-white bg-rose-500 rounded-md hover:bg-rose-600 disabled:opacity-50 disabled:cursor-not-allowed"
                          onClick={AddPolicy}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row flex-wrap gap-2">
                  {scoringPolicy.map((policy, index) => (
                    <div
                      key={index}
                      className="flex flex-row gap-4 items-center justify-between p-1 bg-white border border-gray-300 rounded-md shadow-sm"
                    >
                      <div className="flex flex-col">
                        <span className="text-base ">Range</span>
                        <span className="text-sm">
                          {policy.questionStart} - {policy.questionEnd}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-base ">Negative Marking</span>
                        <span className="text-sm">
                          {policy.negativeMarking}
                        </span>
                      </div>
                      <svg
                        onClick={() => {
                          RemovePolicy(index);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </div>
                  ))}
                </div>

                <div>
                  <label className="block text-gray-900 ">
                    <span>Note (If Any)</span>
                  </label>
                  <textarea
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light h-15"
                    placeholder="Enter Note"
                    name="note"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setFormData({
                        ...formData,
                        note: e.target.value,
                      });
                    }}
                    value={formData.note}
                  />
                </div>

                <div className="flex justify-between items-center gap-2">
                  <div className="flex items-center space-x-2">
                    <label className="flex items-center justify-center cursor-pointer">
                      <span className="mr-2">Publish</span>
                      <Switcher
                        isActive={undefined}
                        for={"isPublished"}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            isPublished: !formData.isPublished,
                          });
                        }}
                        togglevalue={formData.isPublished}
                        size="small"
                      />
                    </label>
                  </div>
                  <div>
                    <label className="block text-gray-900 ">
                      <span>Deck Logo *</span>
                    </label>
                    <input
                      className="w-full rounded bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                      type="file"
                      name="deckLogo"
                      onChange={UploadImageAndConvertTobase64}
                      accept="image/png, image/jpeg"
                    />
                    {errors.deckLogoError.error && (
                      <span className="text-xs text-red-500">
                        {errors.deckLogoError.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  {/* Tag Type Dropdown with clear option */}
                  <div className="mb-4">
                    <label className="block text-gray-900 mb-2">Tag Type</label>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select a Tag Type"
                      value={formData.tagType}
                      onChange={(value) => setFormData({ ...formData, tagType: value })}
                      allowClear={true}
                      options={[
                        { label: "None", value: "" },
                        ...tagTypeOptions.map(option => ({ label: option.label, value: option.value }))
                      ]}
                    />
                  </div>

                  {/* Difficulty Dropdown with clear option */}
                  <div className="mb-4">
                    <label className="block text-gray-900 mb-2">Difficulty</label>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Difficulty"
                      value={formData.difficulty}
                      onChange={(value) => setFormData({ ...formData, difficulty: value })}
                      allowClear={true}
                      options={[
                        { label: "None", value: "" },
                        ...difficultyOptions.map(option => ({ label: option.label, value: option.value }))
                      ]}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end gap-2">
                  <ButtonOutlined
                    handleClick={() => {
                      setShowModel(false);
                      setMode("add");
                      setPopulated(null);
                    }}
                  >
                    Cancel
                  </ButtonOutlined>
                  <ButtonFill
                    handleClick={
                      mode === "add"
                        ? (e: React.FormEvent<HTMLFormElement>) => Submit()
                        : (e: React.FormEvent<HTMLFormElement>) =>
                            EditSubmission()
                    }
                    disabled={disabled}
                  >
                    {mode === "add" ? "Add Deck" : "Edit Deck"}
                  </ButtonFill>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeckAddModal;
