"use client"

import { useState, useEffect, useContext } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-hot-toast"
import { backendUrl } from "constants/config"
import { UserContext } from "state-management/user-context"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { axiosErrorHandler } from "utility/form/functions"

// Custom styled components using pure React/CSS
const Button = ({ children, variant = "primary", onClick, disabled = false, className = "", type = "button" }) => {
  const baseStyle =
    "px-4 py-2 rounded-md font-medium transition-all duration-200 text-sm focus:outline-none focus:ring-2 focus:ring-opacity-50"

  const variants = {
    primary:
      "bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white shadow-md hover:shadow-lg focus:ring-blue-400 disabled:opacity-70 disabled:cursor-not-allowed",
    secondary: "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-gray-400",
    danger: "bg-white border border-red-300 text-red-600 hover:bg-red-50 focus:ring-red-400",
    success: "bg-white border border-green-300 text-green-600 hover:bg-green-50 focus:ring-green-400",
    link: "bg-transparent text-blue-600 hover:text-blue-800 hover:underline p-0 shadow-none",
  }

  return (
    <button
      type={type as "button" | "submit" | "reset"}
      className={`${baseStyle} ${variants[variant]} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const Card = ({ children, className = "" }) => {
  return (
    <div className={`bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden ${className}`}>
      {children}
    </div>
  )
}

const Badge = ({ children, variant = "default" }) => {
  const variants = {
    default: "bg-gray-100 text-gray-800",
    success: "bg-green-100 text-green-800",
    warning: "bg-yellow-100 text-yellow-800",
    danger: "bg-red-100 text-red-800",
    info: "bg-blue-100 text-blue-800",
  }

  return <span className={`px-2.5 py-0.5 text-xs font-medium rounded-full ${variants[variant]}`}>{children}</span>
}

// Icons
const LoaderIcon = () => (
  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
    />
  </svg>
)

const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
    />
  </svg>
)

const ResetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
    />
  </svg>
)

const ResponseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
    />
  </svg>
)

const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
  </svg>
)

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
  </svg>
)

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
)

// Main component
const QuestionnaireDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("Layout must be used within a UserProvider")
  }

  const [questionnaire, setQuestionnaire] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showAddQuestion, setShowAddQuestion] = useState(false)

  useEffect(() => {
    fetchQuestionnaire()
  }, [id])

  const fetchQuestionnaire = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${backendUrl}/api/questionaire/${id}`, { withCredentials: true })
      setQuestionnaire(response.data)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching questionnaire:", error)
      toast.error("Failed to load questionnaire details")
      setLoading(false)
      navigate("/")
    }
  }

  const handleAddQuestion = async (questionData) => {
    try {
      await axios.post(
        `${backendUrl}/api/questionaire/${id}/questions`,
        {
          ...questionData,
        },
        { withCredentials: true },
      )
      toast.success("Question added successfully")
      fetchQuestionnaire()
      setShowAddQuestion(false)
    } catch (error) {
      console.error("Error adding question:", error)
      toast.error("Failed to add question")
    }
  }

  const handleUpdateQuestion = async (questionId, questionData) => {
    try {
      await axios.put(
        `${backendUrl}/api/questionaire/${id}/questions/${questionId}`,
        {
          ...questionData,
        },
        { withCredentials: true },
      )
      toast.success("Question updated successfully")
      fetchQuestionnaire()
    } catch (error) {
      console.error("Error updating question:", error)
      toast.error("Failed to update question")
    }
  }

  const handleDeleteQuestion = async (questionId) => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      try {
        await axios.delete(`${backendUrl}/api/questionaire/${id}/questions/${questionId}`, { withCredentials: true })
        toast.success("Question deleted successfully")
        fetchQuestionnaire()
      } catch (error) {
        console.error("Error deleting question:", error)
        toast.error("Failed to delete question")
      }
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="flex flex-col justify-center items-center">
          <LoaderIcon />
          <p className="text-gray-500 mt-2">Loading questionnaire...</p>
        </div>
      </div>
    )
  }

  if (!questionnaire) {
    return (
      <div className="text-center py-12">
        <p className="text-lg text-gray-600">Questionnaire not found</p>
        <Link
          to="/questionaires"
          className="mt-4 inline-flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-md font-medium hover:from-blue-600 hover:to-indigo-700 transition-colors"
        >
          <BackIcon /> Back to Dashboard
        </Link>
      </div>
    )
  }

  return (
    <div className="w-full mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-8 gap-4">
        <div>
          <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-700 bg-clip-text text-transparent">
            {questionnaire.route}
          </h1>
          <p className="text-gray-600 mt-1">{questionnaire.description}</p>
        </div>
        <div className="flex gap-2">
          <Button
            variant={showAddQuestion ? "secondary" : "primary"}
            onClick={() => setShowAddQuestion(!showAddQuestion)}
            className="flex items-center"
          >
            {showAddQuestion ? <CloseIcon /> : <PlusIcon />}
            <span className="ml-1">{showAddQuestion ? "Cancel" : "Add Question"}</span>
          </Button>
        </div>
      </div>

      {showAddQuestion && (
        <Card className="mb-8 p-6 border-l-4 border-l-blue-500 animate-fadeIn">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">Add New Question</h2>
          <QuestionForm onSubmit={handleAddQuestion} onCancel={() => setShowAddQuestion(false)} />
        </Card>
      )}

      <Card className="mb-8">
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-6 text-gray-800 border-b pb-3">Questions</h2>

          {questionnaire?.questions?.length === 0 ? (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <p className="text-gray-500">No questions added yet</p>
              <Button
                variant="primary"
                onClick={() => setShowAddQuestion(true)}
                className="mt-4 flex items-center mx-auto"
              >
                <PlusIcon /> Add Your First Question
              </Button>
            </div>
          ) : (
            <div className="space-y-4">
              {questionnaire.questions.map((question) => (
                <QuestionItem
                  key={question._id}
                  question={question}
                  onUpdate={(data) => handleUpdateQuestion(question._id, data)}
                  onDelete={() => handleDeleteQuestion(question._id)}
                  fetchQuestionnaire={fetchQuestionnaire}
                />
              ))}
            </div>
          )}
        </div>
      </Card>

      <div className="mt-6">
        <Link
          to="/questionaires"
          className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors"
        >
          <BackIcon /> Back to Dashboard
        </Link>
      </div>
    </div>
  )
}

const QuestionForm = ({ question = null, onSubmit, onCancel }) => {
  const [questionText, setQuestionText] = useState(question ? question.question : "")
  const [status, setStatus] = useState(question ? question.status : "ACTIVE")
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!questionText.trim()) {
      return
    }

    setSubmitting(true)

    onSubmit({
      question: questionText,
      status,
    })

    // Reset form if not provided with onCancel (for adding new questions)
    if (!onCancel) {
      setQuestionText("")
      setStatus("ACTIVE")
      setSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <label htmlFor="question" className="block text-sm font-medium text-gray-700">
          Question
        </label>
        <div className="rounded-md overflow-hidden border border-gray-300 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500">
          <ReactQuill id="question" theme="snow" value={questionText} onChange={setQuestionText} className="bg-white" />
        </div>
      </div>

      <div className="space-y-2">
        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
          Status
        </label>
        <select
          id="status"
          className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors bg-white"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      </div>

      <div className="flex justify-end pt-4 space-x-3">
        {onCancel && (
          <Button type="button" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type="submit" variant="primary" disabled={submitting || !questionText.trim()} onClick={handleSubmit}>
          {submitting ? "Saving..." : question ? "Update Question" : "Add Question"}
        </Button>
      </div>
    </form>
  )
}

const QuestionItem = ({ question, onUpdate, onDelete, fetchQuestionnaire }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [showResponses, setShowResponses] = useState(false)
  const [questionaireResponse, setQuestionaireResponse] = useState([])
  const [showResponseModal, setShowResponseModal] = useState(false)

  const getQuestionaireResponse = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/questionaire/get-questionaire-responses`,
        { questionaire_id: question._id },
        { withCredentials: true },
      )
      if (response.data.success) {
        setQuestionaireResponse(response.data.data)
        setShowResponseModal(true)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      axiosErrorHandler(error, "Failed to get questionnaire response")
    }
  }

  // Modal component for responses
  const ResponseModal = () => {
    if (!showResponseModal) return null

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white rounded-xl p-6 w-full max-w-4xl max-h-[80vh] overflow-y-auto shadow-xl">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-gray-800">Questionnaire Responses</h3>
            <button
              onClick={() => setShowResponseModal(false)}
              className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100 transition-colors"
            >
              <CloseIcon />
            </button>
          </div>

          {questionaireResponse.length === 0 ? (
            <div className="text-center py-12 bg-gray-50 rounded-lg">
              <p className="text-gray-500">No responses yet</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200 rounded-lg overflow-hidden">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Feedback
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {questionaireResponse.map((response, index) => (
                    <tr key={index} className="hover:bg-gray-50 transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap">{response.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{response.email || "N/A"}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="font-medium">{response.feedback}</span>
                          <span className="text-gray-500">/10</span>
                          <div className="ml-2 bg-gray-200 h-2 w-24 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-gradient-to-r from-blue-500 to-indigo-600"
                              style={{ width: `${(response.feedback / 10) * 100}%` }}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    )
  }

  const handleUpdate = (data) => {
    onUpdate(data)
    setIsEditing(false)
  }

  const handleReset = async (id) => {
    if (window.confirm("Are you sure you want to reset this question? All responses will be cleared.")) {
      try {
        await axios.put(`${backendUrl}/api/questionaire/reset/${id}`, { withCredentials: true })
        toast.success("Questionnaire reset successfully")
        fetchQuestionnaire()
      } catch (error) {
        axiosErrorHandler(error, "Failed to reset questionnaire")
      }
    }
  }

  if (isEditing) {
    return (
      <Card className="p-5 border-l-4 border-l-indigo-500 animate-fadeIn">
        <h3 className="font-medium mb-4 text-gray-800">Edit Question</h3>
        <QuestionForm question={question} onSubmit={handleUpdate} onCancel={() => setIsEditing(false)} />
      </Card>
    )
  }

  return (
    <Card className="hover:shadow-md transition-all duration-200 group">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div className="flex-1">
            <div className="prose prose-sm max-w-none" dangerouslySetInnerHTML={{ __html: question.question }} />
            <div className="mt-2 flex items-center gap-3 flex-wrap">
              <Badge variant={question.status === "ACTIVE" ? "success" : "default"}>{question.status}</Badge>
              {/* <span className="text-xs text-gray-500 flex items-center">
                <ResponseIcon />
                {question.responses?.length || 0} {question.responses?.length === 1 ? "response" : "responses"}
              </span> */}
            </div>
          </div>
          <div className="flex gap-2 opacity-70 group-hover:opacity-100 transition-opacity">
            <Button variant="secondary" onClick={() => getQuestionaireResponse()} className="flex items-center text-xs">
              <ResponseIcon />
              <span className="ml-1 hidden sm:inline">Responses</span>
            </Button>
            <Button variant="secondary" onClick={() => handleReset(question._id)} className="flex items-center text-xs">
              <ResetIcon />
              <span className="ml-1 hidden sm:inline">Reset</span>
            </Button>
            <Button variant="secondary" onClick={() => setIsEditing(true)} className="flex items-center text-xs">
              <EditIcon />
              <span className="ml-1 hidden sm:inline">Edit</span>
            </Button>
            <Button variant="danger" onClick={onDelete} className="flex items-center text-xs">
              <DeleteIcon />
              <span className="ml-1 hidden sm:inline">Delete</span>
            </Button>
          </div>
        </div>

        {question?.responses?.length > 0 && (
          <div className="mt-4 pt-3 border-t border-gray-100">
            <Button
              variant="link"
              onClick={() => setShowResponses(!showResponses)}
              className="text-sm flex items-center"
            >
              {showResponses ? "Hide Responses" : "Show Responses"}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-4 w-4 ml-1 transition-transform ${showResponses ? "rotate-180" : ""}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </Button>

            {showResponses && (
              <div className="mt-3">
                <h4 className="text-sm font-medium mb-2 text-gray-700">Recent Responses</h4>
                <div className="space-y-2 max-h-60 overflow-y-auto rounded-lg border border-gray-100">
                  {question.responses.map((response, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center p-3 hover:bg-gray-50 transition-colors"
                    >
                      <div className="text-sm">
                        <span className="font-medium text-gray-800">{response.userId}</span>
                        <span className="text-gray-500 ml-2 text-xs">
                          {new Date(response.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="flex items-center bg-gray-100 px-3 py-1 rounded-full">
                        <span className="text-sm font-medium">{response.rating}</span>
                        <span className="text-xs text-gray-500 ml-1">/5</span>
                        <div className="ml-2 flex">
                          {[...Array(5)].map((_, i) => (
                            <svg
                              key={i}
                              xmlns="http://www.w3.org/2000/svg"
                              className={`h-3 w-3 ${i < response.rating ? "text-yellow-400" : "text-gray-300"}`}
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                            </svg>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <ResponseModal />
    </Card>
  )
}

// Add some global styles
const globalStyles = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }
  
  .prose img {
    border-radius: 0.375rem;
  }
  
  .ql-container.ql-snow {
    border: none !important;
    font-family: inherit !important;
  }
  
  .ql-toolbar.ql-snow {
    border-bottom: 1px solid #e5e7eb !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
`

// Add the styles to the document
const StylesInjector = () => {
  useEffect(() => {
    const style = document.createElement("style")
    style.innerHTML = globalStyles
    document.head.appendChild(style)
    return () => {
      document.head.removeChild(style)
    }
  }, [])
  return null
}

const QuestionnaireDetailWithStyles = () => (
  <>
    <StylesInjector />
    <QuestionnaireDetail />
  </>
)

export default QuestionnaireDetailWithStyles

