import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { useParams } from "react-router-dom";
import SelectTestimonials from "shared-components/select-testimonials";
import Announcements from "shared-pages/courses/components/announcements";
import Assignments from "shared-pages/courses/components/assignments";
import { UserContext } from "state-management/user-context";
import FAQs from "shared-pages/courses/components/faqs";
import Email from "shared-pages/courses/components/email";
import CourseInformation from "shared-pages/courses/components/information";
import CustomTabs from "shared-components/custom-tabs";
import Students from "shared-pages/courses/components/students";
import UpcommingClass from "shared-pages/courses/components/upcomming-class";
import SelectFeatures from "shared-components/select-features";
import Loader from "shared-components/Loader";
import Pricing from "shared-pages/courses/components/pricing";
import Scheduled from "shared-pages/courses/components/schedule/schedule";
import AddStudent from "shared-pages/courses/components/add-student";
import { set } from "react-datepicker/dist/date_utils";

interface WrapperProps {
  access: string;
}

const CourseTabs: React.FC<WrapperProps> = ({ access }) => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [scheduledCourseTopicData, setScheduledCourseTopicData] = useState();
  const [teacherData, setTeacherData] = useState<any[]>([]);
  const [courseAssignmentData, setCourseAssignmentData] = useState<any[]>([]);
  const [courseFeatureData, setCourseFeatureData] = useState<any[]>([]);
  const [subjectData, setSubjectData] = useState<any[]>([]);
  const [testimonialsData, setTestimonialsData] = useState<any[]>([]);
  const [activatedSubscriptions, setActivatedSubscriptions] = useState<any[]>(
    []
  );
  const [deactivatedSubscriptions, setDeactivatedSubscriptions] = useState<
    any[]
  >([]);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState<any[]>([]);

  const [newLoading, setNewLoading] = useState(true);

  const user = useContext(UserContext);

  const getCourseData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getCourseById", null, [id]);
      if (res?.status === 200) {
        setCourseData(res.data.course);
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
      console.error(error);
      setLoading(false);
    }
  };

  const getAllCourseFeatures = async () => {
    setLoading(true);
    const res = await apiRequest("getFeatures");
    if (res?.status === 200) {
      const formatedData = res?.data?.courseFeatures?.map((item: any) => {
        return {
          ...item,
          key: item._id,
        };
      });
      setCourseFeatureData(formatedData);
      setLoading(false);
    }
  };

  const getAllTeachers = async () => {
    setLoading(true);
    const res = await apiRequest("getTeachers");
    if (res?.status === 200) {
      const fomrmatedTeacherData = res?.data?.teachers?.map(
        ({ name, _id }) => ({
          value: _id,
          label: name,
        })
      );
      setTeacherData(fomrmatedTeacherData);
      setLoading(false);
    }
  };

  const getScheduleData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getSchedule", null, [id]);
      if (res?.status === 200) {
        setScheduledCourseTopicData(res.data.scheduledCourses);
        setLoading(false);
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || error.message);
      console.error(error);
      setLoading(false);
    }
  };

  const getAssignmentData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getCourseAssignments", null, [id]);
      if (res?.status === 200) {
        setCourseAssignmentData(res.data.assignments);
        setLoading(false);
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || error.message);
      console.error(error);
      setLoading(false);
    }
  };

  const getAllSubjects = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getallsubjects");
      if (res?.status === 200) {
        const formatedSubjectData = res?.data?.map((subject: any) => ({
          value: subject.name,
          label: subject.name,
        }));
        setSubjectData(formatedSubjectData);
        setLoading(false);
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || error.message);
      console.error(error);
      setLoading(false);
    }
  };

  const fetchAllTestimonials = async () => {
    try {
      const res = await apiRequest("getTestimonials");
      if (res?.status === 200) {
        const formattedData = res.data.Testimonials.map((item: any) => ({
          ...item,
          videothumbnailUrl: item.video?.thumbnailUrl,
          videoUrl: item.video?.videoUrl,
          pictureUrl: item.text?.pictureUrl,
          description: item.text?.description,
          tags: item.text?.tags,
          key: item._id,
        }));
        setTestimonialsData(formattedData);
      }
    } catch (error) {
      // message.error(error?.response?.data?.message || error.message);
      console.error(error);
    }
  };

  const getStudents = async () => {
    setNewLoading(true);
    try {
      setActivatedSubscriptions([]);
      setDeactivatedSubscriptions([]);
      setExpiredSubscriptions([]);

      if (!user) {
        message.error("User not found");
        setNewLoading(false);
        return;
      }

      const response = await apiRequest("getCourseStudents", null, [id]);
      if (response.data.success) {
        response.data.students.forEach((student: any) => {
          if (student.SubscriptionStatus === "Active") {
            setActivatedSubscriptions((prevState) => [...prevState, student]);
          } else if (student.SubscriptionStatus === "Turned Off") {
            setDeactivatedSubscriptions((prevState) => [...prevState, student]);
          }

          if (student.SubscriptionStatus !== "Pending") {
            if (new Date(student.SubscriptionEndDate) < new Date()) {
              setExpiredSubscriptions((prevState) => [...prevState, student]);
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
      // message.error(error?.response?.data?.message || error.message);
    }
    setNewLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          getCourseData(),
          getAllTeachers(),
          getAllCourseFeatures(),
          getScheduleData(),
          getAssignmentData(),
          getAllSubjects(),
          fetchAllTestimonials(),
        ]);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getStudents();
  }, []);

  const storedSelectedTestimonials = courseData?.selectedTestimonials;

  const items = [
    {
      key: "0",
      label: "Course Information",
      children: (
        <CourseInformation
          id={id}
          teacherData={teacherData}
          courseData={courseData}
          getCourseData={getCourseData}
          loading={loading}
          setLoading={setLoading}
        />
      ),
      isTeacher: false,
    },
    {
      key: "1",
      label: "Features",
      children: (
        <SelectFeatures
          featuresData={courseFeatureData}
          updateApi="updateCourse"
          isContentTypeFomrData={true}
          data={courseData}
          fetchData={getCourseData}
          loading={loading}
        />
      ),
      isTeacher: false,
    },
    {
      key: "2",
      label: "Scheduled",
      children: (
        <Scheduled
          id={id}
          // scheduledCourseTopicData={scheduledCourseTopicData}
          // getScheduleData={getScheduleData}
          // loading={loading}
          // subjectData={subjectData}
        />
      ),
      isTeacher: true,
    },
    {
      key: "3",
      label: "FAQs",
      children: (
        <FAQs
          id={id}
          courseData={courseData}
          getCourseData={getCourseData}
          loading={loading}
          setLoading={setLoading}
        />
      ),
      isTeacher: false,
    },
    {
      key: "4",
      label: "Pricing",
      children: (
        <Pricing
          courseData={courseData}
          getCourseData={getCourseData}
          id={id}
          // loading={loading}
          // setLoading={setLoading}
        />
      ),
      isTeacher: false,
    },
    // {
    //   key: "5",
    //   label: "Assignments",
    //   children: (
    //     <Assignments
    //       courseAssignmentData={courseAssignmentData}
    //       getAssignmentData={getAssignmentData}
    //       id={id}
    //       loading={loading}
    //       setLoading={setLoading}
    //       access={access}
    //     />
    //   ),
    //   isTeacher: true,
    // },
    {
      key: "6",
      label: "Testimonials",
      children: (
        <SelectTestimonials
          // data={courseData}
          updateSelectedTestimonialsApi={"updateCourse"}
          id={id}
          isMultiPartFormData={true}
          getData={getCourseData}
          loading={loading}
          // setLoading={setLoading}
          storedSelectedTestimonials={storedSelectedTestimonials}
          // data={testimonialsData}
        />
      ),
      isTeacher: false,
    },
    {
      key: "7",
      label: "Announcements",
      children: <Announcements />,
      isTeacher: true,
    },
    // {
    //   key: "8",
    //   label: "Students",
    //   children: (
    //     <Students
    //       access={access}
    //       setCoursedata={setCourseData}
    //       courseData={courseData}
    //       user={user}
    //       getStudents={getStudents}
    //       activatedSubscrptions={activatedSubscriptions}
    //       deactivatedSubscrptions={deactivatedSubscriptions}
    //       expiredSubscrptions={expiredSubscriptions}
    //       loading={newLoading}
    //     />
    //   ),
    //   isTeacher: true,
    // },
    // {
    //   key: "9",
    //   label: "Emails",
    //   children: <Email activatedSubscriptions={activatedSubscriptions} />,
    //   isTeacher: true,
    // },
    {
      key: "10",
      label: "Upcoming Class",
      children: (
        <UpcommingClass
          courseId={id}
          getCourseData={getCourseData}
          courseData={courseData}
        />
      ),
      isTeacher: true,
    },
    {
      key: "11",
      label: "Add student",
      children: (
        <AddStudent
          courseData={courseData}
        />
      ),
      isTeacher: true,
    },
  ];

  // Filter items based on the access level
  const filteredItems =
    access === "teacher" ? items.filter((item) => item.isTeacher) : items;

  return <> 
  <Loader loading={loading} />
  <CustomTabs items={filteredItems} />
  
  </>
};

export default CourseTabs;
