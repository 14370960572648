import React from "react";
import { useState, useEffect, useContext } from "react";

import { message, notification, Pagination, Select, Tooltip } from "antd";

// @ts-ignore TODO
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import { ViewModal } from "shared-pages/doubtquestion/components/view-modal";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { ChevronLeft, ChevronRight, Delete } from "@material-ui/icons";
import { EditIcon, ManageIcon } from "shared-components/ui/Icons";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";
import toast, { Toaster } from "react-hot-toast";
import Loading from "shared-components/ui/Loading";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";


const EnhancedModal = ({ isOpen, onClose, title, children, footer, maxWidth = "md", showCloseButton = true }) => {
  const [animateIn, setAnimateIn] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setAnimateIn(true)
    } else {
      setAnimateIn(false)
    }
  }, [isOpen])

  if (!isOpen) return null

  const maxWidthClass =
    {
      sm: "max-w-md",
      md: "max-w-2xl",
      lg: "max-w-4xl",
      xl: "max-w-6xl",
      full: "max-w-full",
    }[maxWidth] || "max-w-2xl"

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        style={{ opacity: animateIn ? 1 : 0 }}
        onClick={onClose}
      />

      {/* Modal */}
      <div
        className={`bg-white rounded-lg shadow-xl w-full mx-4 z-10 overflow-hidden transition-all duration-300 ${maxWidthClass}`}
        style={{
          transform: animateIn ? "scale(1)" : "scale(0.9)",
          opacity: animateIn ? 1 : 0,
        }}
      >
        {/* Header */}
        <div className="bg-gradient-to-r from-primary to-primary px-6 py-4 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">{title}</h3>
          {showCloseButton && (
            <button onClick={onClose} className="text-white hover:text-gray-200 focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          )}
        </div>

        {/* Body */}
        <div className="p-6 max-h-[70vh] overflow-y-auto">{children}</div>

        {/* Footer */}
        {footer && <div className="bg-gray-50 px-6 py-4 flex justify-end gap-2 border-t border-gray-200">{footer}</div>}
      </div>
    </div>
  )
}

const StarRating = ({ rating, setRating, readOnly = false }) => {
  const [hover, setHover] = useState(0)

  return (
    <div className="flex items-center gap-2">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1

        return (
          <button
            type="button"
            key={index}
            className={`bg-transparent border-none outline-none ${readOnly ? "cursor-default" : "cursor-pointer"} p-0 text-4xl`}
            onClick={() => !readOnly && setRating(ratingValue)}
            onMouseEnter={() => !readOnly && setHover(ratingValue)}
            onMouseLeave={() => !readOnly && setHover(0)}
            disabled={readOnly}
          >
            <span style={{ color: ratingValue <= (hover || rating) ? "#FFD700" : "#e4e5e9" }}>&#9733;</span>
          </button>
        )
      })}
      <span className="ml-2 text-gray-600 font-medium">{rating}/5</span>
    </div>
  )
}



// Enhanced Feedback View Modal Component
const FeedbackViewModal = ({ isOpen, onClose, data, fetchDoubts }) => {

  const [isLoading, setIsLoading] = useState(false)

  const handleRollback = async () => {
    setIsLoading(true)
    const { data: res } = await apiRequest("rollback-doubt", {
      doubtId: data?._id,
      username: data?.Logs[0]?.expertUsername,
      videoLink: data?.Logs[0]?.videoLink,
    })
    if (res.success) {
      toast.success("Doubt rolled back successfully.")
      onClose()
      fetchDoubts()
    } else {
      toast.error(res.error)
    }
  }


  return (
    <EnhancedModal
      isOpen={isOpen}
      onClose={onClose}
      title="Feedback History"
      maxWidth="lg"
      footer={
        <button
          onClick={onClose}
          className="px-4 py-2 bg-primary text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
        >
          Close
        </button>
      }
    >
      {data?.Logs?.length > 0 ? (
        <div className="space-y-6">
          <div className="flex items-center gap-2 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-gray-600">
              This question has been revised {data.Logs.length} {data.Logs.length === 1 ? "time" : "times"}.
            </p>
          </div>

          {data.Logs.map((log, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
              <div className="bg-gradient-to-r from-blue-50 to-indigo-50 px-6 py-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-gray-800">Feedback #{index + 1}</h3>
                  <span className="text-sm text-gray-500">
                    From: <span className="font-medium text-blue-600">{log?.expertUsername || "Unknown Expert"}</span>
                  </span>
                </div>
              </div>

              <div className="p-6 space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-1">Rating</h4>
                  <StarRating rating={log?.rating || 0} setRating={() => { }} readOnly={true} />
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-1">Feedback</h4>
                  <div className="bg-gray-50 rounded-lg p-4 text-gray-700">
                    {log?.feedback || "No feedback provided."}
                  </div>
                </div>

                {log?.videoLink && (
                  <div className="flex flex-row gap-2 justify-start items-between">
                    <h4 className="text-sm font-medium text-gray-500 mb-1">Video Solution</h4>
                    <a
                      href={log.videoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-rose-600 hover:text-blue-800 transition-colors"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                      </svg>
                      View Video Solution
                    </a>
                  </div>
                )}

                <ButtonFill handleClick={handleRollback}>Rollback Doubt</ButtonFill>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mx-auto text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h3 className="mt-4 text-lg font-medium text-gray-900">No Feedback Available</h3>
          <p className="mt-1 text-gray-500">This question has not received any feedback yet.</p>
        </div>
      )}
    </EnhancedModal>
  )
}


export default function MySubjectiveDoubts() {
  const { subject } = useParams();
  const { user } = useContext(UserContext);
  const [Doubts, setDoubts] = useState<any[]>([]);
  const [ViewDoubt, SetViewDoubt] = useState<Boolean>(false);
  const [Question, SetQuestion] = useState<Object | null>();
  const [DoubtWithId, SetDoubtWithId] = useState<any>();
  const [NumberofSolved, SetNumberofSolved] = useState(0);
  const [NumberofPending, SetNumberOfPending] = useState(0);

  const [DoubtDisplay, SetDoubtDisplay] = useState({});

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalDocCount, setTotalDocCount] = useState<number>(0);
  const fetchDoubts = async (pageNumber = 0) => {
    try {
      setLoading(true);
      const { data } = await apiRequest("GetMySubjectiveDoubts", {
        // subject: subject,
        // username: user?.username,
        page: pageNumber,
      });
      setTotalDocCount(data?.totalDocumentCount);
      setDoubts(data?.filteredDoubts);
      SetNumberofSolved(data.SolvedDocumentCounts);
      SetNumberOfPending(data.PendingDocumentCounts);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.username) {
      fetchDoubts(page + 1);
    }
  }, [page, user.username]);

  const handleViewDoubt = (data) => {
    apiHandler({ name: "GetQuestion", query: { question: data?._id } }).then(
      (res) => {
        if (res.data?.Error) {
          notification.error({
            message: "Error",
            description: res.data?.Error,
          });
        } else {
          SetQuestion(res.data);
        }
      }
    );
    SetViewDoubt(true);
    SetDoubtDisplay(data);
    SetDoubtWithId(data._id);
  };
  const history = useNavigate();

  const TakeDoubt = async () => {
    history(`/expert-interface/subjective/${DoubtWithId?._id}`);
    fetchDoubts();
  };

  const DeleteDoubt = async (id: string) => {
    try {
      const res = await ExpertAPI("DeleteSubjectiveDoubt", id);
      if (res.data.success) {
        toast.success("Doubt deleted successfully");
        fetchDoubts();
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message);
    }
  };

  const [skipModal, setSkipModal] = useState(false);
  const [skipCount, setSkipCount] = useState<number>(0);

  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });

  const [isRevisedViewModal, setIsRevisedViewModal] = useState(false);
  const [isRevisedViewData, setIsRevisedViewData] = useState(null);

  return (
    <>

      <FeedbackViewModal
        isOpen={isRevisedViewModal}
        onClose={() => setIsRevisedViewModal(false)}
        data={isRevisedViewData}
        fetchDoubts={fetchDoubts}
      />

      <div className="w-full md:w-[90%] mx-auto pb-20 px-5">
        {loading && <Loading />}
        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            Subjective Doubts
          </h2>
          <div className="flex items-center flex-end gap-2">
            <ButtonFill
              handleClick={() => {
                setSkipModal(true);
              }}
            >
              Skip to Page
            </ButtonFill>
            {/* <ButtonFill
              handleClick={() => {
                history(`/expert-dashboard/${subject}`);
              }}
            >
              Dashboard
            </ButtonFill> */}
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between gap-2 pb-1 mt-5">
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">
              Pending Questions
            </h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {totalDocCount}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">
              Solved Questions
            </h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {NumberofSolved}
            </h6>
          </div>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        QuestionID
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Edit Question
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts.map((doubt: any, index: number) => (
                      <tr className="border-b" key={doubt?._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-3">
                            <span className="text-gray-600 font-medium">
                              {page === 0 ? index + 1 : page * rowsPerPage + index + 1}
                            </span>
                            {doubt?.Logs && (
                              <Tooltip title="View revision history">
                                <button
                                  className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700 hover:bg-blue-100 transition-colors duration-150"
                                  onClick={() => {
                                    setIsRevisedViewModal(true)
                                    setIsRevisedViewData(doubt)
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                                  </svg>
                                  Revised
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                        <td className="px-4 py-4">{doubt?.QuestionID}</td>
                        <td className="px-4 py-4 text-left">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                doubt?.QuestionText?.length > 100
                                  ? doubt?.QuestionText?.replace(/<[^>]*>?/g, '')
                                    .replace(/\\n/g, ' ')
                                    .replace(/\n/g, ' ')
                                    .replace(/\s+/g, ' ')
                                    .trim()?.slice(0, 100) + "..."
                                  : doubt?.QuestionText?.replace(/<[^>]*>?/g, '')
                                    .replace(/\\n/g, ' ')
                                    .replace(/\n/g, ' ')
                            }}
                          />
                        </td>
                        <td className="px-4 py-4 flex flex-wrap gap-2">
                          {doubt?.subject}
                        </td>
                        <td className="px-4 py-3 text-center">
                          <div className="flex items-center justify-center gap-6">
                            <ManageIcon
                              size="w-6 h-6 hover:cursor-pointer hover:text-primary"
                              onClick={() => {
                                handleViewDoubt(doubt);
                                SetDoubtWithId(doubt);
                              }}
                            />
                          </div>
                        </td>
                        <td className="px-4 py-3 text-center">
                          <EditIcon
                            size="w-6 h-6 hover:cursor-pointer hover:text-primary"
                            onClick={() => {
                              history(`/editquestion/${doubt?.QuestionID}`);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="flex items-center justify-center mt-4 text-center w-full gap-2">
                    <button
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <ChevronLeft />
                    </button>
                    {page + 1} -{" "}
                    {Math.min(totalDocCount, (page + 1) * rowsPerPage)} of{" "}
                    {totalDocCount}
                    <button
                      onClick={() => {
                        if (page < totalDocCount / rowsPerPage - 1) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {skipModal && (
        <div
          id="skip-modal"
          aria-hidden="true"
          className={`${skipModal ? "block" : "hidden"
            }overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mt-8`}
        >
          <div className="relative p-4 w-full max-w-5xl max-h-full flex justify-center items-center mx-auto my-8">
            <div className="relative bg-white rounded-lg shadow-lg flex flex-col p-4">
              <div className="flex items-center justify-between p-4 pb-2 border-b rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Skip Questions
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="skip-modal"
                  onClick={() => {
                    setSkipModal(false);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <input
                type="number"
                value={skipCount}
                placeholder="Enter to any page"
                className="p-2 border border-gray-100 dark:border-gray-600 rounded-md mt-2"
                onChange={(e) => {
                  if (parseInt(e.target.value) > totalDocCount) {
                    message.error(
                      "Page number exceeds total number of questions"
                    );
                  } else {
                    setSkipCount(parseInt(e.target.value));
                  }
                }}
              />
              <div className="flex items-center rounded-b w-full justify-between">
                <ButtonOutlined
                  handleClick={() => {
                    setSkipModal(false);
                  }}
                >
                  Back
                </ButtonOutlined>
                <ButtonFill
                  handleClick={() => {
                    fetchDoubts(skipCount);
                    setSkipModal(false);
                    setPage(skipCount - 1);
                    // SkipToPage(parseInt(skipCount));
                    // setSkipCount(0);
                  }}
                >
                  Skip
                </ButtonFill>
              </div>
            </div>
          </div>
        </div>
      )}

      {ViewDoubt && (
        <>
          <ViewModal
            data={DoubtDisplay}
            open={ViewDoubt}
            SetViewDoubt={SetViewDoubt}
            SetDoubtDisplay={SetDoubtDisplay}
            SetQuestion={SetQuestion}
            TakeDoubt={TakeDoubt}
          />
        </>
      )}
    </>
  );
}
