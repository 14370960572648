import { useState, useEffect, useContext } from "react"
import { message, notification, Select, Tooltip } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { UserContext } from "state-management/user-context"
import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler"
import { ButtonFill } from "shared-components/ui/CustomButtons"
import { ChevronLeft, ChevronRight, Delete } from "@material-ui/icons"
import { AddIcon, EditIcon, ManageIcon } from "shared-components/ui/Icons"
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api"
import toast, { Toaster } from "react-hot-toast"
import Loading from "shared-components/ui/Loading"
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis"

// Enhanced Modal Component
const EnhancedModal = ({ isOpen, onClose, title, children, footer, maxWidth = "md", showCloseButton = true }) => {
  const [animateIn, setAnimateIn] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setAnimateIn(true)
    } else {
      setAnimateIn(false)
    }
  }, [isOpen])

  if (!isOpen) return null

  const maxWidthClass =
    {
      sm: "max-w-md",
      md: "max-w-2xl",
      lg: "max-w-4xl",
      xl: "max-w-6xl",
      full: "max-w-full",
    }[maxWidth] || "max-w-2xl"

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        style={{ opacity: animateIn ? 1 : 0 }}
        onClick={onClose}
      />

      {/* Modal */}
      <div
        className={`bg-white rounded-lg shadow-xl w-full mx-4 z-10 overflow-hidden transition-all duration-300 ${maxWidthClass}`}
        style={{
          transform: animateIn ? "scale(1)" : "scale(0.9)",
          opacity: animateIn ? 1 : 0,
        }}
      >
        {/* Header */}
        <div className="bg-gradient-to-r from-primary to-primary px-6 py-4 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">{title}</h3>
          {showCloseButton && (
            <button onClick={onClose} className="text-white hover:text-gray-200 focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          )}
        </div>

        {/* Body */}
        <div className="p-6 max-h-[70vh] overflow-y-auto">{children}</div>

        {/* Footer */}
        {footer && <div className="bg-gray-50 px-6 py-4 flex justify-end gap-2 border-t border-gray-200">{footer}</div>}
      </div>
    </div>
  )
}

// Star Rating Component
const StarRating = ({ rating, setRating, readOnly = false }) => {
  const [hover, setHover] = useState(0)

  return (
    <div className="flex items-center gap-2">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1

        return (
          <button
            type="button"
            key={index}
            className={`bg-transparent border-none outline-none ${readOnly ? "cursor-default" : "cursor-pointer"} p-0 text-4xl`}
            onClick={() => !readOnly && setRating(ratingValue)}
            onMouseEnter={() => !readOnly && setHover(ratingValue)}
            onMouseLeave={() => !readOnly && setHover(0)}
            disabled={readOnly}
          >
            <span style={{ color: ratingValue <= (hover || rating) ? "#FFD700" : "#e4e5e9" }}>&#9733;</span>
          </button>
        )
      })}
      <span className="ml-2 text-gray-600 font-medium">{rating}/5</span>
    </div>
  )
}

// Enhanced View Modal Component
const ViewModal = ({ data, open, SetViewDoubt, SetDoubtDisplay, SetQuestion, TakeDoubt }) => {
  if (!open) return null

  return (
    <EnhancedModal
      isOpen={open}
      onClose={() => {
        SetViewDoubt(false)
        SetDoubtDisplay({})
        SetQuestion(null)
      }}
      title="Question Details"
      maxWidth="lg"
      footer={
        <>
          <button
            onClick={() => {
              SetViewDoubt(false)
              SetDoubtDisplay({})
              SetQuestion(null)
            }}
            className="px-4 py-2 bg-white text-gray-700 font-medium rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
          >
            Close
          </button>
          <button
            onClick={TakeDoubt}
            className="px-4 py-2 bg-primary text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
          >
            Take Question
          </button>
        </>
      }
    >
      <div className="grid gap-6">
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="font-bold text-lg mb-2">Question ID</h3>
          <p>{data?.QuestionID || "N/A"}</p>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="font-bold text-lg mb-2">Question Text</h3>
          <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: data?.QuestionText || "N/A" }} />
        </div>

        {data?.Tags && data.Tags.length > 0 && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-bold text-lg mb-2">Tags</h3>
            <div className="flex flex-wrap gap-2">
              {data.Tags.map((tag, index) => (
                <span key={index} className="bg-primary text-white px-3 py-1 rounded-full text-sm font-medium">
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </EnhancedModal>
  )
}

// Enhanced Skip Modal Component
const SkipModal = ({ isOpen, onClose, skipCount, setSkipCount, totalDocCount, onSkip }) => {
  return (
    <EnhancedModal
      isOpen={isOpen}
      onClose={onClose}
      title="Skip to Page"
      footer={
        <>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-white text-gray-700 font-medium rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onSkip}
            className="px-4 py-2 bg-primary text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
          >
            Skip
          </button>
        </>
      }
    >
      <div className="space-y-4">
        <p className="text-gray-600">Enter the page number you want to skip to (1-{Math.ceil(totalDocCount / 100)})</p>

        <div className="relative">
          <input
            type="number"
            min="1"
            max={Math.ceil(totalDocCount / 100)}
            value={skipCount}
            onChange={(e) => {
              const value = Number.parseInt(e.target.value)
              if (value > Math.ceil(totalDocCount / 100)) {
                message.error("Page number exceeds total number of pages")
              } else {
                setSkipCount(value)
              }
            }}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
            placeholder="Enter page number"
          />
        </div>
      </div>
    </EnhancedModal>
  )
}

// Enhanced Allocate Doubt Modal Component
const AllocateDoubtModal = ({ modal, closeModal, allUsers, setModal, doubts, setDoubts }) => {
  const [isLoading, setIsLoading] = useState(false)
  const initialState = {
    doubtId: "",
    allocatedUserEmail: "",
  }
  const [formData, setFormData] = useState(initialState)

  useEffect(() => {
    const data = modal?.data
    if (data) {
      setFormData({
        doubtId: data?._id,
        allocatedUserEmail: "",
      })
    }
  }, [modal?.data])

  const resetForm = () => {
    setFormData(initialState)
    setModal({
      isOpen: false,
      data: null,
    })
  }

  const validateForm = (formData) => {
    const { allocatedUserEmail, doubtId } = formData

    if (!allocatedUserEmail || allocatedUserEmail?.trim() === "") {
      toast.error("Please select an allocated user.")
      return false
    }
    if (!doubtId || doubtId?.trim() === "") {
      toast.error("Please select a doubt.")
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm(formData)) {
      return
    }

    try {
      setIsLoading(true)
      const body = formData
      const { data } = await apiRequest("AllocateSubjectiveDoubtToUser", formData)
      if (data) {
        toast.success("Doubt Assigned successfully.")
        setDoubts(doubts.filter((doubt) => doubt?._id !== formData?.doubtId))
        resetForm()
        closeModal()
      }
    } catch (error) {
      console.log("error: ", error)
      const message = error?.response?.data?.message || "Failed to Assign"
      toast.error(message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <EnhancedModal
      isOpen={modal?.isOpen}
      onClose={resetForm}
      title="Assign Doubt"
      footer={
        <>
          <button
            onClick={resetForm}
            disabled={isLoading}
            className="px-4 py-2 bg-white text-gray-700 font-medium rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className="px-4 py-2 bg-primary text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors disabled:opacity-50"
          >
            {isLoading ? "Assigning..." : "Assign"}
          </button>
        </>
      }
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Select User</label>
          <Select
            placeholder="Select user"
            onChange={(value) => setFormData({ ...formData, allocatedUserEmail: value })}
            className="w-full"
            value={formData.allocatedUserEmail}
          >
            {allUsers.map((user, index) => (
              <Select.Option key={index} value={user.username}>
                {user.username}
              </Select.Option>
            ))}
          </Select>
        </div>

        {modal?.data && (
          <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <h3 className="font-medium text-gray-700 mb-2">Selected Question</h3>
            <p className="text-sm text-gray-600">
              {modal.data?.QuestionText?.replace(/<[^>]*>?/g, "")
                .replace(/\\n/g, " ")
                .replace(/\n/g, " ")
                .replace(/\s+/g, " ")
                .trim()
                ?.slice(0, 100)}
              {modal.data?.QuestionText?.length > 100 ? "..." : ""}
            </p>
          </div>
        )}
      </form>
    </EnhancedModal>
  )
}

// Enhanced Feedback View Modal Component
const FeedbackViewModal = ({ isOpen, onClose, data, setDoubts, doubts }) => {

  const [isLoading, setIsLoading] = useState(false)

  const handleRollback = async () => {
    setIsLoading(true)
    const { data: res } = await apiRequest("rollback-doubt", {
      doubtId: data?._id,
      username: data?.Logs[0]?.expertUsername,
      videoLink: data?.Logs[0]?.videoLink,
    })
    if (res.success) {
      toast.success("Doubt rolled back successfully.")
      setDoubts(doubts.filter((doubt) => doubt?._id !== data?._id))
      onClose()
    } else {
      toast.error(res.error)
    }
  }


  return (
    <EnhancedModal
      isOpen={isOpen}
      onClose={onClose}
      title="Feedback History"
      maxWidth="lg"
      footer={
        <button
          onClick={onClose}
          className="px-4 py-2 bg-primary text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
        >
          Close
        </button>
      }
    >
      {data?.Logs?.length > 0 ? (
        <div className="space-y-6">
          <div className="flex items-center gap-2 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-gray-600">
              This question has been revised {data.Logs.length} {data.Logs.length === 1 ? "time" : "times"}.
            </p>
          </div>

          {data.Logs.map((log, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
              <div className="bg-gradient-to-r from-blue-50 to-indigo-50 px-6 py-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-gray-800">Feedback #{index + 1}</h3>
                  <span className="text-sm text-gray-500">
                    From: <span className="font-medium text-blue-600">{log?.expertUsername || "Unknown Expert"}</span>
                  </span>
                </div>
              </div>

              <div className="p-6 space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-1">Rating</h4>
                  <StarRating rating={log?.rating || 0} setRating={() => { }} readOnly={true} />
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-1">Feedback</h4>
                  <div className="bg-gray-50 rounded-lg p-4 text-gray-700">
                    {log?.feedback || "No feedback provided."}
                  </div>
                </div>

                {log?.videoLink && (
                  <div className="flex flex-row gap-2 justify-start items-between">
                    <h4 className="text-sm font-medium text-gray-500 mb-1">Video Solution</h4>
                    <a
                      href={log.videoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-rose-600 hover:text-blue-800 transition-colors"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                      </svg>
                      View Video Solution
                    </a>
                  </div>
                )}

                <ButtonFill handleClick={handleRollback}>Rollback Doubt</ButtonFill>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mx-auto text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h3 className="mt-4 text-lg font-medium text-gray-900">No Feedback Available</h3>
          <p className="mt-1 text-gray-500">This question has not received any feedback yet.</p>
        </div>
      )}
    </EnhancedModal>
  )
}

// Main Component
export default function ExpertSubject() {
  const { subject } = useParams()
  const { user } = useContext(UserContext)
  const [Doubts, setDoubts] = useState([])
  const [ViewDoubt, SetViewDoubt] = useState(false)
  const [Question, SetQuestion] = useState(null)
  const [DoubtWithId, SetDoubtWithId] = useState(null)
  const [NumberofSolved, SetNumberofSolved] = useState(0)
  const [NumberofPending, SetNumberOfPending] = useState(0)
  const [DoubtDisplay, SetDoubtDisplay] = useState({})
  const [loading, setLoading] = useState(true)
  const [isRevisedViewModal, setIsRevisedViewModal] = useState(false)
  const [isRevisedViewData, setIsRevisedViewData] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [totalDocCount, setTotalDocCount] = useState(0)
  const [skipModal, setSkipModal] = useState(false)
  const [skipCount, setSkipCount] = useState(1)
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  })
  const [users, setAllUsers] = useState([])
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const history = useNavigate()

  const fetchDoubts = async (pageNumber = 0) => {
    try {
      setLoading(true)
      const { data } = await apiRequest("GetSubjectiveExpertDoubts", {
        subject: subject,
        username: user?.username,
        page: pageNumber,
      })
      setTotalDocCount(data?.totalDocumentCount)
      setDoubts(data?.filteredDoubts)
      SetNumberofSolved(data.SolvedDocumentCounts)
      SetNumberOfPending(data.PendingDocumentCounts)
    } catch (error) {
      message.error(error?.response?.data?.error || error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user.username) {
      fetchDoubts(page + 1)
    }
  }, [page, user.username])

  const handleViewDoubt = (data) => {
    apiHandler({ name: "GetQuestion", query: { question: data?._id } }).then((res) => {
      if (res.data?.Error) {
        notification.error({
          message: "Error",
          description: res.data?.Error,
        })
      } else {
        SetQuestion(res.data)
      }
    })
    SetViewDoubt(true)
    SetDoubtDisplay(data)
    SetDoubtWithId(data)
  }

  const TakeDoubt = async () => {
    history(`/expert-interface/subjective/${DoubtWithId?._id}`)
    fetchDoubts()
  }

  const DeleteDoubt = async (id) => {
    try {
      const res = await ExpertAPI("DeleteSubjectiveDoubt", id)
      if (res.data.success) {
        toast.success("Doubt deleted successfully")
        fetchDoubts()
      } else {
        toast.error(res.data.error)
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error.message)
    }
  }

  async function fetchAllUsers() {
    setIsFetchingUsers(true)

    try {
      const response = await BasicGetApi("getUserInfo", [], null, {
        isAdmin: true,
      })
      if (response) {
        const apiData = response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []

        const permissionsSet = new Set(["Expert Access", "Admin Expert Access"])
        const filterExpertAccessUsers = apiData?.filter((user) => {
          return user?.admin?.pagepermissions?.some((permission) => permissionsSet.has(permission))
        })
        setAllUsers(filterExpertAccessUsers)
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Failed to Fetch Users."
      toast.error(message)
    } finally {
      setIsFetchingUsers(false)
    }
  }

  useEffect(() => {
    fetchAllUsers()
  }, [])

  const handleSkip = () => {
    fetchDoubts(skipCount)
    setSkipModal(false)
    setPage(skipCount - 1)
  }

  return (
    <>
      <div className="w-full md:w-[98%] mx-auto pb-20 px-5">
        {loading && <Loading />}
        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Subjective Doubts</h2>
          <div className="flex items-center flex-end gap-2">
            <ButtonFill
              handleClick={() => {
                setSkipModal(true)
              }}
            >
              Skip to Page
            </ButtonFill>
            <ButtonFill
              handleClick={() => {
                history(`/expert-dashboard/${subject}`)
              }}
            >
              Dashboard
            </ButtonFill>
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center justify-between gap-2 pb-1 mt-5">
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Pending Questions</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">{totalDocCount}</h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Solved Questions</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">{NumberofSolved}</h6>
          </div>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        QuestionID
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4 text-left">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts.map((doubt, index) => (
                      <tr className="border-b hover:bg-gray-50 transition-colors duration-150" key={doubt?._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-3">
                            <span className="text-gray-600 font-medium">
                              {page === 0 ? index + 1 : page * rowsPerPage + index + 1}
                            </span>
                            {doubt?.Logs && (
                              <Tooltip title="View revision history">
                                <button
                                  className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700 hover:bg-blue-100 transition-colors duration-150"
                                  onClick={() => {
                                    setIsRevisedViewModal(true)
                                    setIsRevisedViewData(doubt)
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                                  </svg>
                                  Revised
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-gray-900 font-medium">{doubt?.QuestionID}</span>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-gray-700 line-clamp-2">
                            {doubt?.QuestionText?.replace(/<[^>]*>?/g, "")
                              .replace(/\\n/g, " ")
                              .replace(/\n/g, " ")
                              .replace(/\s+/g, " ")
                              .trim()}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex flex-wrap gap-2">
                            {doubt?.Tags?.map((tag) => (
                              <span
                                key={tag.name}
                                className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-indigo-50 text-indigo-700"
                              >
                                {tag.name}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center justify-center gap-4">
                            <Tooltip title="View Details">
                              <button
                                className="p-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors duration-150"
                                onClick={() => {
                                  handleViewDoubt(doubt)
                                  SetDoubtWithId(doubt)
                                }}
                              >
                                <ManageIcon size="w-5 h-5" />
                              </button>
                            </Tooltip>
                            <Tooltip title="Edit Question">
                              <button
                                className="p-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors duration-150"
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_CATEGORY !== "MEDSCHOOL"
                                      ? "https://premed-admin.parho.io"
                                      : "https://medschool-admin.parho.io"
                                    }/editQuestion/${doubt.QuestionID}`,
                                    "_blank",
                                  )
                                }}
                              >
                                <EditIcon size="w-5 h-5" />
                              </button>
                            </Tooltip>
                            <Tooltip title="Delete Question">
                              <button
                                className="p-1.5 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors duration-150"
                                onClick={() => {
                                  DeleteDoubt(doubt?._id)
                                }}
                              >
                                <Delete className="w-5 h-5" />
                              </button>
                            </Tooltip>
                            <Tooltip title="Assign Question">
                              <button
                                className="p-1.5 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors duration-150"
                                onClick={() => {
                                  setModal({
                                    isOpen: true,
                                    data: doubt,
                                  })
                                }}
                              >
                                <AddIcon size="w-5 h-5" />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="flex items-center justify-center mt-4 text-center w-full gap-2">
                    <button
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1)
                        }
                      }}
                    >
                      <ChevronLeft />
                    </button>
                    {page + 1} - {Math.min(totalDocCount, (page + 1) * rowsPerPage)} of {totalDocCount}
                    <button
                      onClick={() => {
                        if (page < totalDocCount / rowsPerPage - 1) {
                          setPage(page + 1)
                        }
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Enhanced Skip Modal */}
      <SkipModal
        isOpen={skipModal}
        onClose={() => setSkipModal(false)}
        skipCount={skipCount}
        setSkipCount={setSkipCount}
        totalDocCount={totalDocCount}
        onSkip={handleSkip}
      />

      {/* Enhanced View Modal */}
      {ViewDoubt && (
        <ViewModal
          data={DoubtDisplay}
          open={ViewDoubt}
          SetViewDoubt={SetViewDoubt}
          SetDoubtDisplay={SetDoubtDisplay}
          SetQuestion={SetQuestion}
          TakeDoubt={TakeDoubt}
        />
      )}

      {/* Enhanced Allocate Doubt Modal */}
      <AllocateDoubtModal
        modal={modal}
        allUsers={users}
        closeModal={() => {
          setModal({
            isOpen: false,
            data: null,
          })
        }}
        setModal={setModal}
        doubts={Doubts}
        setDoubts={setDoubts}
      />

      {/* Enhanced Feedback View Modal */}
      <FeedbackViewModal
        isOpen={isRevisedViewModal}
        onClose={() => setIsRevisedViewModal(false)}
        data={isRevisedViewData}
        setDoubts={setDoubts}
        doubts={Doubts}
      />


      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
            borderRadius: "8px",
          },
          success: {
            duration: 3000,
            iconTheme: {
              primary: "#10B981",
              secondary: "white",
            },
          },
          error: {
            duration: 4000,
            iconTheme: {
              primary: "#EF4444",
              secondary: "white",
            },
          },
        }}
      />
    </>
  )
}

