import React from "react";
import CustomBadges from "shared-components/ui/CustomBadges";
import { InfoIcon } from "shared-components/ui/Icons";
import {
  GetTime,
  renderChips,
  ROlES,
} from "shared-pages/latest-commission/utils";

const ViewPendingSales = ({
  orders,
  setCouponDetailsModal,
  supervisorOnly = false,
  supervisorEmail = "",
  showMySales = false,
  user,
  coupon,
  associatePendingSales = [],
}) => {
  // console.log("ViewPendingSales: ", orders);
  // console.log("user : ", user);
  // console.log("coupon : ", coupon);

  let commission =
    user?.role === ROlES.MANAGER
      ? coupon?.commissionPercentage
      : user?.commissionPercentage;

  return (
    <table className="w-full mb-20 text-sm text-left text-gray-500">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <th scope="col" className="px-4 py-4">
            No
          </th>
          <th scope="col" className="px-4 py-4 text-left">
            Date of Order
          </th>
          <th scope="col" className="px-4 py-4 text-left">
            Purchased User Email
          </th>
          <th scope="col" className="px-4 py-4 text-left">
            Products
          </th>

          <th scope="col" className="px-4 py-4 text-center">
            At Commission %
          </th>
          <th scope="col" className="px-4 py-4 text-center">
            Total Amount Earned
          </th>
          <th scope="col" className="px-4 py-4 text-center">
            User Paid Amount
          </th>
          <th scope="col" className="px-4 py-4 text-left">
            Coupon
          </th>
          <th scope="col" className="px-4 py-4 text-left">
            Order Status
          </th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order: any, index: number) => {
          let commission =
            user?.role === ROlES.MANAGER
              ? order?.CouponCode === coupon?.CouponCode
                ? coupon?.commissionPercentage
                : user.commissionPercentage
              : user?.commissionPercentage;

          return (
            <tr className="border-b" key={order?._id}>
              <td className="px-4 py-3">{index + 1}</td>
              <td className="px-4 py-3 text-left text-xs">
                {order?.createdAt
                  ? GetTime(order?.createdAt) +
                    " " +
                    new Date(order?.createdAt)?.toLocaleDateString()
                  : "N/A"}
              </td>
              <td className="px-4 py-3 text-left">{order?.UserName}</td>
              <td className="px-4 py-3  text-left text-sm">
                {order?.PRODUCTS && order?.PRODUCTS?.length > 0
                  ? order?.PRODUCTS?.join(",")
                  : "N/A"}
              </td>

              <td className="px-4 py-3 text-center">{commission}%</td>
              <td className="px-4 py-3 text-center">
                {(order?.BREAKDOWN?.GrandTotal || 0) *
                  ((commission || 0) / 100)}
              </td>
              <td className="px-4 py-3 text-center">
                {order?.BREAKDOWN?.GrandTotal || "N/A"}
              </td>

              <td className="px-4 py-3 text-left">
                {renderChips(order?.CouponCode)}
              </td>
              <td className="px-4 py-3 text-left">
                {" "}
                <CustomBadges
                  label="Pending"
                  type="warning"
                  showIcon={false}
                  textSize={"sm"}
                />
              </td>
            </tr>
          );
        })}

        {}
      </tbody>
    </table>
  );
};

export default ViewPendingSales;
