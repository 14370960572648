import React, { useState } from "react";
import Modal from "./components/modal";
import ViewPastPapers from "./components/view";
import { ButtonFill } from "shared-components/ui/CustomButtons";

const PastPapers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="container mx-auto">
        <header className="mb-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-800">Past Papers</h1>
          <ButtonFill handleClick={() => setIsModalOpen(true)}>
            Add New Past Paper
          </ButtonFill>
        </header>

        {/* Modal for Adding/Editing Textbooks */}
        <Modal isModalOpen={isModalOpen} closeModal={closeModal} />

        {/* Textbooks Table */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <ViewPastPapers />
        </div>
      </div>
    </div>
  );
};

export default PastPapers;
