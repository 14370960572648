import React, { useEffect, useState } from "react";
import { message, Select } from "antd";
// @ts-ignore
import { useParams } from "react-router-dom";

import { useContext } from "react";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Loading from "shared-components/ui/Loading";
import Loader from "shared-components/Loader";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api";
import toast, { Toaster } from "react-hot-toast";

export default function ExpertSubjectManage() {
  const { subject } = useParams();
  const [ExpertUsernames, SetExpertUsernames] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState("All");
  const [Doubts, setDoubts] = useState([]);
  const [Original, setOriginal] = useState([]);
  const { user } = useContext(UserContext);
  const [TotalSolved, SetTotalSolved] = useState(0);
  const [TotalPending, SetTotalPending] = useState(0);
  const [isFeedbackModal, setIsFeedbackModal] = useState(false);

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    // GetExpertEmails()
    //   .then((res) => {
    //     SetExpertUsernames(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    apiRequest("getexpertemails")
      .then((res) => {
        console.log("emails data", res.data);
        SetExpertUsernames(["All", ...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });

    apiRequest("getsubjectstats", { subject })
      .then((res) => {
        SetTotalSolved(res.data.totalsolved);
        SetTotalPending(res.data.totalpending);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUsernameChange = async (value) => {
    setSelectedUsername(value);
    FetchDoubt(value);
  };
  const [loading, setLoading] = useState(false);
  const FetchDoubt = async (value) => {
    try {
      setLoading(true);
      const response = await apiRequest("GetExpertSolvedDoubts", {
        subject,
        username: value,
      });
      const data = response.data;
      if (data?.result?.length > 0) {
        setDoubts(data.result);
        setTotalDocCount(data.result.length);
      } else {
        message.error("Data Not Found");
      }
    } catch (error) {
      console.error("Error fetching doubts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchDoubt("all");
  }, [subject]);

  const ReviseDoubt = (id: string) => {
    apiRequest("redodoubt", { id }).then((res) => {
      console.log(res);
      if (res.data.success) {
        toast.success("Doubt Revise Requested");
        setDoubts([]);
        FetchDoubt(selectedUsername);
      }
    });
  };

  const Reset = () => {
    setDoubts([]);
    setOriginal([]);
    setSelectedUsername("All");
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalDocCount, setTotalDocCount] = useState<number>(0);
  const formik = useFormik({
    initialValues: {
      feedback: "",
      rating: 1,
      doubtId: "",
      videoLink:"",

    },
    validationSchema: Yup.object({
      feedback: Yup.string().required("Feedback is required"),
      rating: Yup.number()
        .required("Rating is required")
        .min(1, "Rating must be at least 1")
        .max(5, "Rating must be at most 5"),
    }),
    onSubmit: async (values :any) => {
      try {
        console.log("values", values)

        const res = await ExpertAPI("AddORUpdateFeedback", values?.doubtId, {
          feedback: values.feedback,
          rating: values.rating,
          videoLink:values?.videoLink,
          expertUsername: user?.username
        });
        toast.success("Feedback Submitted");
        setIsFeedbackModal(false);
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    },
  });
  
  
  return (
    <>
      <div className="w-full md:w-[90%] mx-auto">
        <div className="flex md:flex-row flex-col items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">
            Admin Subjective Doubts
          </h2>
          <ButtonFill handleClick={Reset}>Reset</ButtonFill>
        </div>

        <div className="flex items-center md:flex-row flex-col justify-between gap-2 pb-1 mt-5">
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Subject</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {capitalizeFirstLetter(subject)}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Manager</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {user?.fullname}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Solved</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {TotalSolved}
            </h6>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-lg w-full">
            <h6 className="text-gray-700 font-semibold text-lg">Pending</h6>
            <h6 className="text-gray-950 font-extrabold text-3xl">
              {TotalPending}
            </h6>
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center justify-between gap-2 pb-1 mt-5">
          <div className="w-full">
            <Select
              className="w-full h-14 bg-gray-50 rounded-lg shadow-lg border-none"
              showSearch
              placeholder="Select Username"
              optionFilterProp="children"
              onChange={handleUsernameChange}
              value={selectedUsername}
              bordered={false}
            >
              {ExpertUsernames &&
                ExpertUsernames?.map((username) => {
                  return (
                    <Select.Option value={username} key={username}>
                      {username}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="w-full bg-gray-50 p-4 rounded-lg shadow-lg">
            <h6>
              <b>Question Solved:</b> {Doubts.length}
            </h6>
          </div>
        </div>

        {loading && <Loader loading={loading} />}

        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className=" bg-white shadow-md sm:rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        SR No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Question Text
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Tags
                      </th>
                      <th scope="col" className="px-4 py-3 text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doubts.length === 0 && (
                      <h2 className="font-bold text-2xl mb-4">
                        No Doubts Solved Yet
                      </h2>
                    )}
                    {Doubts.slice(
                      page * rowsPerPage,
                      (page + 1) * rowsPerPage
                    ).map((doubt, index) => (
                      <tr className="border-b" key={doubt._id}>
                        <td className="px-4 py-4">
                          {page === 0
                            ? index + 1
                            : page * rowsPerPage + index + 1}
                        
                        </td>
                        <td className="px-4 py-4">
                          {doubt.QuestionText.slice(0, 45)
                            ?.replace("<p>", "")
                            .replace("</p>", "")
                            .replace("<span", "")
                            .replace("</span>", "") + "..."}
                        </td>
                        <td className="px-4 py-4 flex flex-wrap gap-2">
                          {doubt?.subject}
                          {/* {doubt?.Tags?.map((tag: any) => (
                            <div className="bg-primary px-2 py-1 rounded border font-bold border-primary mr-2 text-sm text-white">
                              {tag.name}
                            </div>
                          ))} */}
                        </td>
                        <td className="px-4 py-4">
                          <div className="flex items-center justify-center gap-2">
                            <ButtonOutlined
                              handleClick={() => {
                                window.open(`${doubt?.videoLink}`, "_blank");
                              }}
                            >
                              Watch
                            </ButtonOutlined>
                            <ButtonFill
                              handleClick={() => {
                                setIsFeedbackModal(true);
                                formik.setFieldValue("doubtId", doubt._id);
                                formik.setFieldValue("videoLink", doubt.videoLink);
                                // ReviseDoubt(doubt._id)
                              }}
                            >
                              Revise
                            </ButtonFill>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="flex items-center justify-center mt-4 text-center w-full gap-2">
                    <button
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <ChevronLeft />
                    </button>
                    {page + 1} -{" "}
                    {Math.min(totalDocCount, (page + 1) * rowsPerPage)} of{" "}
                    {totalDocCount}
                    <button
                      onClick={() => {
                        if (page < totalDocCount / rowsPerPage - 1) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      <ChevronRight />
                    </button>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              background: "#363636",
              color: "#fff",
              borderRadius: "8px",
            },
            success: {
              duration: 3000,
              iconTheme: {
                primary: "#10B981",
                secondary: "white",
              },
            },
            error: {
              duration: 4000,
              iconTheme: {
                primary: "#EF4444",
                secondary: "white",
              },
            },
          }}
        />


      <FeedbackModal
        isOpen={isFeedbackModal}
        onClose={() => setIsFeedbackModal(false)}
        onSubmit={async (values) => {
          try {
            const res = await ExpertAPI("AddORUpdateFeedback", values.doubtId, {
              feedback: values.feedback,
              rating: values.rating,
              videoLink: values.videoLink,
              expertUsername: user?.username
            });
            toast.success("Feedback Submitted");
            setIsFeedbackModal(false);
            setDoubts([]);
            FetchDoubt(selectedUsername);
          } catch (error) {
            console.error("Error submitting feedback:", error);
          }
        }}
        doubtId={formik.values.doubtId}
        videoLink={formik.values.videoLink}
      />
    </>
  );
};

// Star rating component
const StarRating = ({ rating, setRating }) => {
  const [hover, setHover] = useState(0)

  return (
    <div className="flex items-center gap-2">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1

        return (
          <button
            type="button"
            key={index}
            className="bg-transparent border-none outline-none cursor-pointer p-0 text-4xl"
            onClick={() => setRating(ratingValue)}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(0)}
          >
            <span style={{ color: ratingValue <= (hover || rating) ? "#FFD700" : "#e4e5e9" }}>&#9733;</span>
          </button>
        )
      })}
      <span className="ml-2 text-gray-600 font-medium">{rating}/5</span>
    </div>
  )
}

// Enhanced feedback modal
const FeedbackModal = ({ isOpen, onClose, onSubmit, doubtId, videoLink }) => {
  const [animateIn, setAnimateIn] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setAnimateIn(true)
    } else {
      setAnimateIn(false)
    }
  }, [isOpen])

  const formik = useFormik({
    initialValues: {
      feedback: "",
      rating: 3,
      doubtId: doubtId || "",
      videoLink: videoLink || "",
    },
    validationSchema: Yup.object({
      feedback: Yup.string().required("Feedback is required"),
      rating: Yup.number()
        .required("Rating is required")
        .min(1, "Rating must be at least 1")
        .max(5, "Rating must be at most 5"),
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  })

  useEffect(() => {
    if (doubtId) {
      formik.setFieldValue("doubtId", doubtId)
    }
    if (videoLink) {
      formik.setFieldValue("videoLink", videoLink)
    }
  }, [doubtId, videoLink])

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        style={{ opacity: animateIn ? 1 : 0 }}
        onClick={onClose}
      />

      {/* Modal */}
      <div
        className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4 z-10 overflow-hidden transition-all duration-300"
        style={{
          transform: animateIn ? "scale(1)" : "scale(0.9)",
          opacity: animateIn ? 1 : 0,
        }}
      >
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 px-6 py-4 flex justify-between items-center">
          <h3 className="text-xl font-bold text-white">Expert Feedback</h3>
          <button onClick={onClose} className="text-white hover:text-gray-200 focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Body */}
        <form onSubmit={formik.handleSubmit}>
          <div className="p-6">
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">How would you rate this solution?</label>
              <StarRating rating={formik.values.rating} setRating={(value) => formik.setFieldValue("rating", value)} />
            </div>

            <div className="mb-4">
              <label htmlFor="feedback" className="block text-gray-700 text-sm font-bold mb-2">
                Your Feedback
              </label>
              <textarea
                id="feedback"
                name="feedback"
                rows={4}
                className={`w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none ${
                  formik.touched.feedback && formik.errors.feedback ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Please provide detailed feedback about the solution..."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.feedback}
              />
              {formik.touched.feedback && formik.errors.feedback && (
                <p className="text-red-500 text-xs italic mt-1">{formik.errors.feedback}</p>
              )}
            </div>

            {/* Video link preview if available */}
            {videoLink && (
              <div className="mb-4 p-3 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">Solution Video</span>
                  <a
                    href={videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700 text-sm font-medium"
                  >
                    Open Video
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="bg-gray-50 px-6 py-4 flex justify-end gap-2 border-t border-gray-200">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-white text-gray-700 font-medium rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Submitting..." : "Submit Feedback"}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}



