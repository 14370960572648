import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { backendUrl } from 'constants/config';
import { axiosErrorHandler } from 'utility/form/functions';
import { Loader } from 'lucide-react';

const QuestionnaireForm = ({ ShowModel, setShowModel , isEditMode , id , fetchQuestionnaires }: { ShowModel: boolean, setShowModel: (value: boolean) => void , isEditMode: boolean , id: string , fetchQuestionnaires: () => void }) => {

    const [route, setRoute] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    useEffect(() => {
        if (isEditMode) {
            fetchQuestionnaire();
        }
    }, [id]);

    const fetchQuestionnaire = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${backendUrl}/api/questionaire/${id}`, {withCredentials: true});
            const { route, description } = response.data;
            setRoute(route);
            setDescription(description);
            setLoading(false);
        } catch (error) {
            axiosErrorHandler(error, 'Failed to load questionnaire details');
            setLoading(false);
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(route, description);
        if (!route.trim() || !description.trim()) {
            toast.error('Please fill in all fields');
            return;
        }

        try {
            setSubmitting(true);

            const data = {
                route,
                description,
            };

            if (isEditMode) {
                await axios.put(`${backendUrl}/api/questionaire/${id}`, data, {withCredentials: true});
                toast.success('Questionnaire updated successfully');
            } else {
                await axios.post(`${backendUrl}/api/questionaire`, data, {withCredentials: true});
                toast.success('Questionnaire created successfully');
            }
            setShowModel(false);
            fetchQuestionnaires();
        } catch (error) {
            axiosErrorHandler(error, 'Failed to save questionnaire');
            setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
            <div className='flex flex-col justify-center items-center'>
                <Loader className='animate-spin' />
                <p className='text-gray-500'>Loading questionnaires...</p>
            </div>
        </div>
        );
    }

    return (

        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-4xl max-h-full p-4">
                <div className="relative p-4 ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {isEditMode ? 'Edit Questionnaire' : 'Create New Questionnaire'}
                        </h3>
                    </div>

                    <div className="bg-white rounded-lg shadow p-6">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col gap-2 mb-4">
                                <label htmlFor="route">Route</label>
                                <input
                                    type="text"
                                    id="route"
                                    disabled={submitting || isEditMode}
                                    placeholder="Route"
                                    className="border p-2 rounded w-full bg-transparent disabled:bg-gray-100"
                                    value={route}
                                    onChange={(e) => setRoute(e.target.value)}
                                />
                            </div>

                            <div className="flex flex-col gap-2 mb-6">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    id="description"
                                    placeholder="Description"
                                    className="border p-2 rounded w-full bg-transparent h-32"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="mr-2 px-4 py-2 border border-gray-300 rounded bg-white text-gray-700 hover:bg-gray-50"
                                    onClick={() => setShowModel(false)}
                                    disabled={submitting}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:bg-indigo-400"
                                    disabled={submitting}
                                >
                                    {submitting ? 'Saving...' : isEditMode ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default QuestionnaireForm;
