import { FlashcardAPI } from "(apis)/(shared-apis)/flashcard-api";
import { Edit2, EyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { Input } from "shared-components/ui/CustomForm";
import { FileUpload } from "shared-components/ui/file-upload";

const FlashCardDecksManager = () => {
  const [decks, setDecks] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreateSubjectModalOpen, setIsCreateSubjectModalOpen] =
    useState(false);
  const [newDeck, setNewDeck] = useState({
    deck_name: "",
    is_published: false,
    logo: null,
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingDeck, setEditingDeck] = useState({
    _id: "",
    deck_name: "",
    is_published: false,
    logo: null,
  });

  const handleCreateDeck = async () => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      const response = await FlashcardAPI("createDeck", newDeck);

      if (response?.data?.success) {
        toast.success("Deck created successfully");
        setNewDeck({
          deck_name: "",
          is_published: false,
          logo: null,
        });
        setRefreshData((prev) => prev + 1);
        setIsCreateModalOpen(false);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setNewDeck((prev) => ({
        ...prev,
        logo: e.target.result as string,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleEditDeck = async () => {
    setIsLoading(true);
    try {
      const response = await FlashcardAPI(
        "updateDeck",
        {
          deck_id: editingDeck._id,
          deck_name: editingDeck.deck_name,
          is_published: editingDeck.is_published,
          logo: editingDeck.logo,
        },
        [editingDeck._id]
      );

      if (response?.data?.success) {
        toast.success("Deck updated successfully");
        setIsEditModalOpen(false);
        setRefreshData((prev) => prev + 1);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditLogoUpload = (file: File | null) => {
    if (!file) {
      // Handle removal case
      setEditingDeck((prev) => ({
        ...prev,
        logo: null,
      }));
      return;
    }

    // Handle upload case
    const reader = new FileReader();
    reader.onload = (e) => {
      setEditingDeck((prev) => ({
        ...prev,
        logo: e.target.result as string,
      }));
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    fetchDecks();
  }, [refreshData]);

  const fetchDecks = async () => {
    setIsLoading(true);
    try {
      const response = await FlashcardAPI("getAllDecks");

      if (response?.data?.success) {
        setDecks(response.data.data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredDecks = decks.filter((deck) =>
    deck.deck_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-full pb-20 px-5">
      <Toaster />

      <div className="flex items-center justify-between pb-1 mt-5">
        <h2 className="text-2xl font-bold text-gray-700">Deck Manager</h2>
        <div className="flex space-x-2">
          <ButtonFill handleClick={() => setIsCreateModalOpen(true)}>
            Create Deck
          </ButtonFill>
        </div>
      </div>

      <div className="flex items-center space-x-3 my-5">
        <Input
          type="text"
          placeholder="Search decks..."
          value={searchQuery}
          onChange={(name, value) => setSearchQuery(value)}
        />
      </div>

      {/* Replace Create Deck Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold mb-4">Create New Deck</h2>
            </div>
            
            <form onSubmit={(e) => { e.preventDefault(); handleCreateDeck(); }} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Deck Name
                </label>
                <Input
                  type="text"
                  placeholder="Enter deck name"
                  value={newDeck.deck_name}
                  onChange={(name, value) =>
                    setNewDeck((prev) => ({ ...prev, deck_name: value }))
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Deck Logo
                </label>
                <FileUpload
                  onFileSelect={handleLogoUpload}
                  value={newDeck.logo || undefined}
                  previewType="image"
                />
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={newDeck.is_published}
                  onChange={(e) =>
                    setNewDeck((prev) => ({
                      ...prev,
                      is_published: e.target.checked,
                    }))
                  }
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label className="text-sm text-gray-900">Publish Deck</label>
              </div>

              <div className="flex justify-end space-x-2 mt-6">
                <ButtonOutlined
                  type="button"
                  handleClick={() => setIsCreateModalOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </ButtonOutlined>
                <ButtonFill 
                  handleClick={handleCreateDeck}
                  disabled={isLoading || !newDeck.deck_name}
                >
                  {isLoading ? "Creating..." : "Create"}
                </ButtonFill>
              </div>
            </form>
          </div>
        </div>
      )}

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-3">
                Logo
              </th>
              <th scope="col" className="px-4 py-3">
                Deck Name
              </th>
              <th scope="col" className="px-4 py-3">
                Subjects
              </th>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredDecks?.map((deck) => (
              <tr key={deck._id} className="border-b">
                <td className="px-4 py-3">
                  {deck.logo ? (
                    <img
                      src={deck.logo}
                      alt="Deck Logo"
                      className="w-12 h-12 object-cover rounded-full"
                    />
                  ) : (
                    <div className="w-12 h-12 bg-gray-200 rounded-full"></div>
                  )}
                </td>
                <td className="px-4 py-3">{deck?.deck_name}</td>
                <td className="px-4 py-3">{deck?.subjects?.length}</td>
                <td className="px-4 py-3">
                  <span
                    className={`px-3 py-1 rounded-full text-xs font-medium ${
                      deck?.is_published
                        ? "bg-green-100 text-green-800"
                        : "bg-yellow-100 text-yellow-800"
                    }`}
                  >
                    {deck?.is_published ? "Published" : "Unpublished"}
                  </span>
                </td>
                <td className="px-4 py-3 text-right items-center justify-start flex space-x-2">
                  <button
                    onClick={() => {
                      window.open(
                        `/manage/flashcards/decks/${deck?._id}`,
                        "_blank"
                      );
                    }}
                  >
                    <EyeIcon size={20} />
                  </button>

                  <button
                    onClick={() => {
                      setEditingDeck({
                        _id: deck._id,
                        deck_name: deck.deck_name,
                        is_published: deck.is_published,
                        logo: deck.logo,
                      });
                      setIsEditModalOpen(true);
                    }}
                    className="text-gray-600 hover:text-blue-500 transition-colors duration-200 mr-2"
                    title="Edit"
                  >
                    <Edit2 size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Replace Edit Deck Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold mb-4">Edit Deck</h2>
            </div>
            
            <form onSubmit={(e) => { e.preventDefault(); handleEditDeck(); }} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Deck Name
                </label>
                <Input
                  type="text"
                  placeholder="Enter deck name"
                  value={editingDeck.deck_name}
                  onChange={(name, value) =>
                    setEditingDeck((prev) => ({ ...prev, deck_name: value }))
                  }
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Deck Logo
                </label>
                <FileUpload
                  onFileSelect={(file) => handleEditLogoUpload(file || null)}
                  value={editingDeck.logo || undefined}
                  previewType="image"
                />
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={editingDeck.is_published}
                  onChange={(e) =>
                    setEditingDeck((prev) => ({
                      ...prev,
                      is_published: e.target.checked,
                    }))
                  }
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label className="text-sm text-gray-900">Publish Deck</label>
              </div>

              <div className="flex justify-end space-x-2 mt-6">
                <ButtonOutlined
                  type="button"
                  handleClick={() => setIsEditModalOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </ButtonOutlined>
                <ButtonFill 
                  handleClick={handleEditDeck}
                  disabled={isLoading || !editingDeck.deck_name}
                >
                  {isLoading ? "Saving..." : "Save Changes"}
                </ButtonFill>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlashCardDecksManager;
