"use client"

import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast, Toaster } from "react-hot-toast"
import { backendUrl } from "constants/config"
import QuestionnaireForm from "./add-new"
import { UserContext } from "state-management/user-context"
import { axiosErrorHandler } from "utility/form/functions"

// Custom styled components using pure React/CSS
const Button = ({ children, variant = "primary", onClick, disabled = false, className = "", type = "button" }) => {
  const baseStyle =
    "px-4 py-2 rounded-md font-medium transition-all duration-200 text-sm focus:outline-none focus:ring-2 focus:ring-opacity-50"

  const variants = {
    primary:
      "bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white shadow-md hover:shadow-lg focus:ring-blue-400 disabled:opacity-70 disabled:cursor-not-allowed",
    secondary: "bg-white border border-gray-300 text-gray-700 hover:bg-gray-50 focus:ring-gray-400",
    danger: "bg-white border border-red-300 text-red-600 hover:bg-red-50 focus:ring-red-400",
    success: "bg-white border border-green-300 text-green-600 hover:bg-green-50 focus:ring-green-400",
    link: "bg-transparent text-blue-600 hover:text-blue-800 hover:underline p-0 shadow-none",
  }

  return (
    <button
      type={type as "button" | "submit" | "reset"}
      className={`${baseStyle} ${variants[variant]} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

const Card = ({ children, className = "" }) => {
  return (
    <div className={`bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden ${className}`}>
      {children}
    </div>
  )
}

const Badge = ({ children, variant = "default" }) => {
  const variants = {
    default: "bg-gray-100 text-gray-800",
    success: "bg-green-100 text-green-800",
    warning: "bg-yellow-100 text-yellow-800",
    danger: "bg-red-100 text-red-800",
    info: "bg-blue-100 text-blue-800",
  }

  return <span className={`px-2.5 py-0.5 text-xs font-medium rounded-full ${variants[variant]}`}>{children}</span>
}

// Icons
const LoaderIcon = () => (
  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
  </svg>
)

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
    />
  </svg>
)

const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
    />
  </svg>
)

const ViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
)

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
)

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    />
  </svg>
)

const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 mr-1"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

// Main component
const Questionaire = () => {
  const [questionnaires, setQuestionnaires] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModel, setShowModel] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [id, setId] = useState("")

  const context = useContext(UserContext)
  if (!context) {
    throw new Error("Layout must be used within a UserProvider")
  }

  useEffect(() => {
    fetchQuestionnaires()
  }, [])

  const fetchQuestionnaires = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${backendUrl}/api/questionaire`, {
        withCredentials: true,
      })
      setQuestionnaires(response.data)
      setLoading(false)
    } catch (error) {
      console.error("Error fetching questionnaires:", error)
      axiosErrorHandler(error, "Failed to load questionnaires")
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this questionnaire?")) {
      try {
        await axios.delete(`${backendUrl}/api/questionaire/${id}`, { withCredentials: true })
        toast.success("Questionnaire deleted successfully")
        fetchQuestionnaires()
      } catch (error) {
        console.error("Error deleting questionnaire:", error)
        axiosErrorHandler(error, "Failed to delete questionnaire")
      }
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="flex flex-col justify-center items-center">
          <LoaderIcon />
          <p className="text-gray-500 mt-2">Loading questionnaires...</p>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-8 gap-4">
        <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-700 bg-clip-text text-transparent">
          Questionnaires
        </h1>
        <Button
          onClick={() => {
            setShowModel(true)
            setIsEditMode(false)
            setId("")
          }}
          className="flex items-center"
        >
          <PlusIcon /> Create Questionnaire
        </Button>
      </div>
      <Toaster />

      {questionnaires.length === 0 ? (
        <Card className="p-12 text-center">
          <div className="max-w-md mx-auto">
            <div className="mb-6 bg-blue-50 rounded-full w-20 h-20 flex items-center justify-center mx-auto">
              <QuestionIcon  />
            </div>
            <h2 className="text-xl font-semibold mb-2 text-gray-800">No questionnaires found</h2>
            <p className="text-gray-500 mb-6">
              Create your first questionnaire to start collecting feedback from your users.
            </p>
            <Button
              onClick={() => {
                setShowModel(true)
                setIsEditMode(false)
                setId("")
              }}
              className="flex items-center mx-auto"
            >
              <PlusIcon /> Create Your First Questionnaire
            </Button>
          </div>
        </Card>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {questionnaires.map((questionnaire) => (
            <Card key={questionnaire._id} className="group hover:shadow-md transition-all duration-200">
              <div className="p-6">
                <div className="flex items-start justify-between">
                  <h2 className="text-xl font-semibold mb-2 text-gray-800 truncate">{questionnaire.route}</h2>
                  <Badge variant="info">
                    {questionnaire?.questions?.length || 0} Questions
                  </Badge>
                </div>
                <p className="text-gray-600 mb-4 text-sm line-clamp-2">
                  {questionnaire.description || "No description provided"}
                </p>

                <div className="space-y-2 mb-4">
                  <div className="flex items-center text-xs text-gray-500">
                    <CalendarIcon />
                    <span>Updated: {new Date(questionnaire.updatedAt).toLocaleDateString()}</span>
                  </div>

                  <div className="flex items-center text-xs text-gray-500">
                    <UserIcon />
                    <span>Created by: {questionnaire?.createdBy || "N/A"}</span>
                  </div>

                  <div className="flex items-center text-xs text-gray-500">
                    <UserIcon />
                    <span>Updated by: {questionnaire?.updatedBy || "N/A"}</span>
                  </div>
                </div>
              </div>

              <div className="bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-3 flex justify-between items-center border-t border-gray-100">
                <Link
                  to={`/questionnaires/${questionnaire._id}`}
                  className="text-blue-600 hover:text-blue-800 text-sm font-medium flex items-center"
                >
                  <ViewIcon /> View Details
                </Link>
                <div className="flex space-x-3">
                  <button
                    onClick={() => {
                      setShowModel(true)
                      setIsEditMode(true)
                      setId(questionnaire._id)
                    }}
                    className="text-gray-600 hover:text-gray-800 text-sm font-medium flex items-center"
                  >
                    <EditIcon /> Edit
                  </button>
                  <button
                    onClick={() => handleDelete(questionnaire._id)}
                    className="text-red-600 hover:text-red-800 text-sm font-medium flex items-center"
                  >
                    <DeleteIcon /> Delete
                  </button>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}

      <QuestionnaireForm
        ShowModel={showModel}
        setShowModel={setShowModel}
        isEditMode={isEditMode}
        id={id}
        fetchQuestionnaires={fetchQuestionnaires}
      />
    </div>
  )
}

// Add some global styles
const StylesInjector = () => {
  useEffect(() => {
    const style = document.createElement("style")
    style.innerHTML = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(10px); }
        to { opacity: 1; transform: translateY(0); }
      }
      
      .animate-fadeIn {
        animation: fadeIn 0.3s ease-out forwards;
      }
      
      .line-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    `
    document.head.appendChild(style)
    return () => {
      document.head.removeChild(style)
    }
  }, [])
  return null
}

const QuestionaireWithStyles = () => (
  <>
    <StylesInjector />
    <Questionaire />
  </>
)

export default QuestionaireWithStyles

