import React from "react";
import { format } from "date-fns";

interface Bundle {
  planName: string;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  customTag?: string;
  entityPart?: string;
  categoryPart?: string;
  features?: string[];
}

interface Course {
  course_name: string;
  sku_name: string;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  type: string;
  tag?: string;
  status: string;
}

interface Component {
  Access: string;
  SubscriptionStartDate: string;
  SubscriptionEndDate: string;
}

interface UserPurchases {
  bundles: Bundle[];
  courses: Course[];
  ownBundles: Component[];
}

interface Props {
  selectedUser: { fullname: string } | null;
  closePurchaseModal: () => void;
  userPurchases: UserPurchases | null;
}

const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "N/A";
  try {
    return format(new Date(dateString), "dd MMM yyyy");
  } catch (error) {
    return "Invalid Date";
  }
};

const UserPurchasesModal: React.FC<Props> = ({
  selectedUser,
  closePurchaseModal,
  userPurchases,
}) => {
  const renderBundleSection = (bundle: Bundle, index: number) => (
    <div key={index} className="border border-gray-200 p-3 rounded-md shadow-sm hover:shadow-md transition-shadow bg-white">
      <div className="flex justify-between items-start mb-2">
        <h4 className="text-sm font-semibold text-gray-800">{bundle.planName || "Unnamed Bundle"}</h4>
        {bundle.customTag && (
          <span className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-700">
            {bundle.customTag}
          </span>
        )}
      </div>
      <div className="space-y-1 text-xs">
        <p className="text-gray-600">
          <span className="font-medium">Start:</span> {formatDate(bundle.subscriptionStartDate)}
        </p>
        <p className="text-gray-600">
          <span className="font-medium">End:</span> {formatDate(bundle.subscriptionEndDate)}
        </p>
        {bundle.entityPart && (
          <p className="text-gray-600">
            <span className="font-medium">Entity:</span> {bundle.entityPart}
          </p>
        )}
        {bundle.categoryPart && (
          <p className="text-gray-600">
            <span className="font-medium">Category:</span> {bundle.categoryPart}
          </p>
        )}
      </div>
      {bundle.features && bundle.features.length > 0 && (
        <div className="mt-2">
          <p className="text-xs font-medium text-gray-700 mb-1">Features:</p>
          <div className="flex flex-wrap gap-1">
            {bundle.features.map((feature, idx) => (
              <span
                key={idx}
                className="px-2 py-0.5 text-[10px] rounded bg-gray-100 text-gray-700"
              >
                {feature}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const renderCourseSection = (course: Course, index: number) => (
    <div key={index} className="border border-gray-200 p-3 rounded-md shadow-sm hover:shadow-md transition-shadow bg-white">
      <div className="flex justify-between items-start mb-2">
        <h4 className="text-sm font-semibold text-gray-800">{course.course_name || "Unnamed Course"}</h4>
        <span className={`px-2 py-1 text-xs rounded-full ${
          course.status === "Active" ? "bg-green-100 text-green-700" : "bg-gray-100 text-gray-700"
        }`}>
          {course.status}
        </span>
      </div>
      <div className="space-y-1 text-xs">
        <p className="text-gray-600">
          <span className="font-medium">SKU:</span> {course.sku_name || "N/A"}
        </p>
        <p className="text-gray-600">
          <span className="font-medium">Start:</span> {formatDate(course.subscriptionStartDate)}
        </p>
        <p className="text-gray-600">
          <span className="font-medium">End:</span> {formatDate(course.subscriptionEndDate)}
        </p>
        <p className="text-gray-600">
          <span className="font-medium">Type:</span> {course.type || "N/A"}
        </p>
        {course.tag && (
          <p className="text-gray-600">
            <span className="font-medium">Tag:</span> {course.tag}
          </p>
        )}
      </div>
    </div>
  );

  const renderComponentSection = (component: Component, index: number) => (
    <div key={index} className="border border-gray-200 p-3 rounded-md shadow-sm hover:shadow-md transition-shadow bg-white">
      <h4 className="text-sm font-semibold text-gray-800 mb-2">{component.Access || "Unnamed Component"}</h4>
      <div className="space-y-1 text-xs">
        <p className="text-gray-600">
          <span className="font-medium">Start:</span> {formatDate(component.SubscriptionStartDate)}
        </p>
        <p className="text-gray-600">
          <span className="font-medium">End:</span> {formatDate(component.SubscriptionEndDate)}
        </p>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-50 rounded-lg shadow-xl w-full max-w-5xl max-h-[90vh] overflow-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-800">
            {selectedUser?.fullname ? `${selectedUser.fullname}'s Purchases` : 'User Purchases'}
          </h2>
          <button
            onClick={closePurchaseModal}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-6">
          {userPurchases ? (
            <>
              {/* Bundles Section */}
              <section>
                <div className="flex items-center justify-between mb-3">
                  <h3 className="text-sm font-semibold text-gray-700">Bundles</h3>
                  <span className="text-xs text-gray-500">{userPurchases.bundles.length} items</span>
                </div>
                {userPurchases.bundles.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {userPurchases.bundles.map(renderBundleSection)}
                  </div>
                ) : (
                  <p className="text-xs text-gray-500 text-center py-4 bg-white rounded-md">No bundles purchased</p>
                )}
              </section>

              {/* Courses Section */}
              <section>
                <div className="flex items-center justify-between mb-3">
                  <h3 className="text-sm font-semibold text-gray-700">Courses</h3>
                  <span className="text-xs text-gray-500">{userPurchases.courses.length} items</span>
                </div>
                {userPurchases.courses.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {userPurchases.courses.map(renderCourseSection)}
                  </div>
                ) : (
                  <p className="text-xs text-gray-500 text-center py-4 bg-white rounded-md">No courses purchased</p>
                )}
              </section>

              {/* Individual Components Section */}
              <section>
                <div className="flex items-center justify-between mb-3">
                  <h3 className="text-sm font-semibold text-gray-700">Individual Components</h3>
                  <span className="text-xs text-gray-500">{userPurchases.ownBundles.length} items</span>
                </div>
                {userPurchases.ownBundles.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {userPurchases.ownBundles.map(renderComponentSection)}
                  </div>
                ) : (
                  <p className="text-xs text-gray-500 text-center py-4 bg-white rounded-md">No individual components</p>
                )}
              </section>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center py-12 bg-white rounded-lg">
              <svg className="w-12 h-12 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p className="text-sm text-gray-500">No purchase information available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPurchasesModal;
