import React, { useState, useCallback, useMemo, useEffect } from "react";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { Input, Label } from "shared-components/ui/CustomForm";
import { RichTextEditor } from "shared-components/ui/rich-text-editor";
import { FileUpload } from "shared-components/ui/file-upload";

export const QuestionModal = ({
  isOpen,
  onClose,
  question,
  onQuestionChange,
  onSave,
  isLoading,
  isEditing,
  report_page = false,
  on_report_reject = null,
  report_status = null,
}) => {
  const [tagInput, setTagInput] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    if (question.image instanceof File) {
      setImagePreview(URL.createObjectURL(question.image));
    } else if (typeof question.image === "string") {
      setImagePreview(question.image);
    } else {
      setImagePreview(null);
    }
  }, [question.image]);

  const DifficultyBlock = ({ level, selected, onClick }) => (
    <div
      onClick={onClick}
      className={`w-8 h-8 rounded cursor-pointer flex items-center justify-center transition-colors ${
        selected
          ? "bg-primary text-white"
          : "bg-gray-100 hover:bg-gray-200 text-gray-700"
      }`}
    >
      {level}
    </div>
  );

  const handleAddTag = () => {
    const trimmedTag = tagInput.trim();
    if (!trimmedTag || question.tags?.includes(trimmedTag)) return;

    onQuestionChange({
      ...question,
      tags: [...(question.tags || []), trimmedTag],
    });
    setTagInput("");
  };

  const handleRemoveTag = (tagToRemove) => {
    onQuestionChange({
      ...question,
      tags: question.tags?.filter((tag) => tag !== tagToRemove) || [],
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(e);
  };

  const handleImageChange = (file: File | null) => {
    onQuestionChange((prev) => ({ ...prev, image: file }));
  };

  const handleQuestionTextChange = useCallback(
    (value) => {
      if (value !== question.question_text) {
        onQuestionChange((prev) => ({
          ...prev,
          question_text: value,
        }));
      }
    },
    [onQuestionChange]
  );

  const handleAnswerTextChange = useCallback(
    (value) => {
      if (value !== question.answer_text) {
        onQuestionChange((prev) => ({
          ...prev,
          answer_text: value,
        }));
      }
    },
    [onQuestionChange]
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg w-[800px] max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold mb-4">
            {isEditing ? "Edit Question" : "Create Question"}
          </h2>
          {report_page && on_report_reject && report_status !== "REJECTED" && (
            <button
              type="button"
              className="bg-red-100 border border-red-500 text-red-700 px-4 py-2 rounded-md hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              disabled={isLoading}
              onClick={() => {
                on_report_reject?.();
              }}
            >
              Reject
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label>Question Text</Label>
            <RichTextEditor
              value={question.question_text || ""}
              onChange={handleQuestionTextChange}
              placeholder="Enter question text"
            />
          </div>

          <div>
            <Label>Answer Text</Label>
            <RichTextEditor
              value={question.answer_text || ""}
              onChange={handleAnswerTextChange}
              placeholder="Enter answer text"
            />
          </div>

          <div>
            <Label>Description</Label>
            <Input
              type="text"
              value={question.description || ""}
              onChange={(name, value) =>
                onQuestionChange((prev) => ({ ...prev, description: value }))
              }
              placeholder="Enter description"
            />
          </div>

          <div>
            <Label>Reference</Label>
            <Input
              type="text"
              value={question.reference || ""}
              onChange={(name, value) =>
                onQuestionChange((prev) => ({ ...prev, reference: value }))
              }
              placeholder="Enter reference"
            />
          </div>

          <div>
            <Label>Tags</Label>
            <div className="mb-4">
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter tag (press Enter to add)"
                  className="flex-grow p-2 border rounded-md focus:ring-primary focus:border-primary"
                />
                <button
                  type="button"
                  onClick={handleAddTag}
                  className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary/80"
                >
                  Add
                </button>
              </div>
              {question.tags?.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {question.tags.map((tag, index) => (
                    <div
                      key={index}
                      className="bg-gray-100 px-3 py-1 rounded-full flex items-center space-x-2"
                    >
                      <span>{tag}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveTag(tag)}
                        className="text-red-500 hover:text-red-700"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div>
            <Label>Image</Label>
            <FileUpload
              onFileSelect={handleImageChange}
              accept="image/*"
              maxSize={2 * 1024 * 1024} // 2MB
              previewType="image"
              value={imagePreview}
            />
          </div>

          <div>
            <Label>Difficulty</Label>
            <div className="flex gap-2 mt-2">
              {[1, 2, 3, 4, 5].map((level) => (
                <DifficultyBlock
                  key={level}
                  level={level}
                  selected={question.difficulty === level}
                  onClick={() =>
                    onQuestionChange((prev) => ({ ...prev, difficulty: level }))
                  }
                />
              ))}
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Select difficulty level from 1 (easiest) to 5 (hardest)
            </p>
          </div>

          <div>
            <Label>Extra Information</Label>
            <Input
              type="text"
              value={question.extra || ""}
              onChange={(name, value) =>
                onQuestionChange((prev) => ({ ...prev, extra: value }))
              }
              placeholder="Enter extra information"
            />
          </div>

          <div className="flex justify-end space-x-2 mt-6">
            <ButtonOutlined
              type="button"
              handleClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </ButtonOutlined>

            <ButtonFill type="Submit" disabled={isLoading}>
              {isLoading
                ? "Saving..."
                : isEditing
                ? report_page
                  ? "Resolve"
                  : "Update"
                : "Create"}
            </ButtonFill>
          </div>
        </form>
      </div>
    </div>
  );
};
