import { useState } from "react";
import toast from "react-hot-toast";

interface NavigationButtonsProps {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    onSubmit: () => void;
    disabled: boolean;
    onReject?: (reason: string) => void;
    reportId?: string;
}

export default function NavigationButtons({ page, setPage, onSubmit, disabled, onReject, reportId }: NavigationButtonsProps) {
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [rejectReason, setRejectReason] = useState("");

    const handlePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleReject = () => {
        if (rejectReason.trim() === "") {
            toast.error("Please enter a reason for rejection");
            return;
        }

        if (onReject && rejectReason.trim()) {
            onReject(rejectReason);
            setShowRejectPopup(false); // Close popup after rejecting
            setRejectReason(""); // Reset reason
        }
    };

    return (
        <div className="flex flex-row flex-wrap items-center justify-between">
            <button
                className="border shadow-lg border-green-500 hover:bg-green-500 hover:text-white text-green-500 font-bold py-3 px-6 rounded"
                disabled={page === 1}
                onClick={() => handlePage(page - 1)}
            >
                Back
            </button>

            <div className="flex flex-row flex-wrap items-center gap-2">
                <button
                    className="border shadow-lg border-green-500 hover:bg-green-500 hover:text-white text-green-500 font-bold py-3 px-6 rounded"
                    disabled={page === 3}
                    onClick={() => handlePage(page + 1)}
                >
                    Next
                </button>

                <button
                    className="border shadow-lg border-rose-500 bg-rose-500 hover:bg-white hover:text-rose-500 text-white font-bold py-3 px-6 rounded 
                    disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    Submit
                </button>

                {reportId && (
                    <button
                        className="border shadow-lg border-rose-500 bg-rose-500 hover:bg-white hover:text-rose-500 text-white font-bold py-3 px-6 rounded 
                        disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={disabled}
                        onClick={() => setShowRejectPopup(true)}
                    >
                        Reject
                    </button>
                )}
            </div>

            {/* Reject Popup */}
            {showRejectPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h2 className="text-lg font-bold mb-4">Reason for Rejection</h2>
                        <textarea
                            className="w-full border rounded p-2"
                            placeholder="Enter reason..."
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)}
                        />
                        <div className="flex justify-end gap-2 mt-4">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                                onClick={() => setShowRejectPopup(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-rose-500 text-white px-4 py-2 rounded disabled:opacity-50"
                                disabled={!rejectReason.trim()}
                                onClick={handleReject}
                            >
                                Confirm Reject
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
