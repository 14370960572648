import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const FlashcardAPIs: ApiInterface[] = [
  // Subject APIs
  {
    url: "/api/flashcards-new/subject/getall",
    method: "GET",
    name: "getAllSubjects",
  },
  {
    url: "/api/flashcards-new/subject/create",
    method: "POST",
    name: "createSubject",
  },
  {
    url: "/api/flashcards-new/subject/update",
    method: "PUT",
    name: "updateSubject",
  },
  {
    url: "/api/flashcards-new/subject/delete",
    method: "DELETE",
    name: "deleteSubject",
  },

  // Question APIs
  {
    url: "/api/flashcards-new/question/getall",
    method: "GET",
    name: "getAllQuestions",
  },
  {
    url: "/api/flashcards-new/question/create",
    method: "POST",
    name: "createQuestion",
  },
  {
    url: "/api/flashcards-new/question/update",
    method: "PUT",
    name: "updateQuestion",
  },
  {
    url: "/api/flashcards-new/question/delete",
    method: "DELETE",
    name: "deleteQuestion",
  },
  {
    url: "/api/flashcards-new/question/update-question-status",
    method: "PUT",
    name: "updateQuestionPublishStatus",
  },
  {
    url: "/api/flashcards-new/deck/delete",
    method: "DELETE",
    name: "deleteDeck",
  },

  // Deck APIs
  {
    url: "/api/flashcards-new/deck/getall",
    method: "GET",
    name: "getAllDecks",
  },
  {
    url: "/api/flashcards-new/deck/create",
    method: "POST",
    name: "createDeck",
  },
  {
    url: "/api/flashcards-new/deck/update",
    method: "PUT",
    name: "updateDeck",
  },
  {
    url: "/api/flashcards-new/deck/delete",
    method: "DELETE",
    name: "deleteDeck",
  },
  {
    url: "/api/flashcards-new/deck",
    method: "GET",
    name: "getDeckById",
  },
  {
    url: "/api/flashcards-new/deck/update",
    method: "PUT",
    name: "updateDeck",
  },
  {
    url: "/api/flashcards-new/subject",
    method: "GET",
    name: "getSubjectById",
  },
  {
    url: "/api/flashcards-new/get-flashcard-reports",
    method: "GET",
    name: "getFlashcardsReport",
  },
  {
    url: "/api/flashcards-new/change-flashcard-report-status",
    method: "PUT",
    name: "changeFlashcardsReportStatus",
  },
];

export function FlashcardAPI(
  name: string,
  data: any = null,
  params: string[] = [""]
) {
  const api = FlashcardAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url =
    backendUrl +
    api.url +
    (params && params.length > 0 ? "/" + params.join("/") : "");

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url,
  });
}
