import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { SearchIcon } from "lucide-react";
import UserPurchasesModal from "./user-purchases-modal";
import Select from "react-select";
import Papa from 'papaparse';

interface User {
  _id: string;
  username: string;
  fullname: string;
  accountcreateddate: string;
  phonenumber?: string;
  city?: string;
  province?: string;
  triggers: string[];
  courses_bundles: any[];
  seperateComponents: any[];
  featuresPurchased: any[];
  school: string;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalUsers: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

interface UserPurchases {
  bundles: any[];
  courses: any[];
  ownBundles: any[];
}

interface Filters {
  searchInputValue: string;
  searchQuery: string;
  selectedTrigger: string;
  selectedTimeFilter: string;
  startDate: string;
  endDate: string;
  selectedCity: string;
  selectedProvince: string;
  selectedUserType: string;
  selectedPlans: string[];
}

const UserFilterScreen = () => {
  // Filter states
  const [filters, setFilters] = useState<Filters>({
    searchInputValue: "",
    searchQuery: "",
    selectedTrigger: "All",
    selectedTimeFilter: "All",
    startDate: "",
    endDate: "",
    selectedCity: "",
    selectedProvince: "",
    selectedUserType: "All",
    selectedPlans: [],
  });

  // Data states
  const [users, setUsers] = useState<User[]>([]);
  const [triggers, setTriggers] = useState<string[]>([]);
  const [plans_names, set_plans_names] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [pagination, setPagination] = useState<PaginationInfo>({
    currentPage: 1,
    totalPages: 1,
    totalUsers: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });

  // Modal states
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userPurchases, setUserPurchases] = useState<UserPurchases | null>(
    null
  );
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  // Add a new state to track if it's a new search
  const [isNewSearch, setIsNewSearch] = useState(true);

  // State for triggers modal
  const [isTriggersModalOpen, setIsTriggersModalOpen] = useState(false);
  const [selectedUserTriggers, setSelectedUserTriggers] = useState<string[]>(
    []
  );

  // Update individual filter states using a single function
  const updateFilter = (name: string, value: string | string[]) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // Fetch triggers on component mount
  useEffect(() => {
    fetchTriggers();
    fetchAllPlansName();
  }, []);

  async function fetchTriggers() {
    try {
      const response = await CommissionAPI("getAllTriggers");
      if (response?.data?.success && Array.isArray(response.data.triggers)) {
        setTriggers(["All", ...response.data.triggers]);
      } else {
        setTriggers(["All"]);
        toast.error("Invalid triggers data received");
      }
    } catch (error) {
      setTriggers(["All"]);
      toast.error("Failed to fetch triggers");
      console.error("Error fetching triggers:", error);
    }
  }
  async function fetchAllPlansName() {
    try {
      const response = await CommissionAPI("getAllPlansName");
      if (response?.data?.success && Array.isArray(response?.data?.data)) {
        set_plans_names(["All", ...response?.data?.data]);
      } else {
        set_plans_names(["All"]);
        toast.error("Invalid Plan Names data received");
      }
    } catch (error) {
      set_plans_names(["All"]);
      console.error("Error fetching triggers:", error);
    }
  }

  async function fetchUsers(overridePage?: number) {
    setIsFetching(true);
    try {
      const queryParams = {
        page: overridePage || pagination.currentPage,
        limit: 50,
        ...(filters.selectedTimeFilter !== "All" && {
          timeFilter: filters.selectedTimeFilter.toLowerCase(),
        }),
        ...(filters.startDate && { startDate: filters.startDate }),
        ...(filters.endDate && { endDate: filters.endDate }),
        ...(filters.selectedTrigger !== "All" && {
          trigger: filters.selectedTrigger,
        }),
        ...(filters.selectedCity !== "" && { city: filters.selectedCity }),
        ...(filters.selectedProvince !== "" && {
          province: filters.selectedProvince,
        }),
        ...(filters.searchQuery?.trim() && {
          searchQuery: filters.searchQuery.trim(),
        }),
        ...(filters.selectedUserType !== "All" && {
          userType: filters.selectedUserType,
        }),
        ...(filters.selectedPlans.length > 0 && {
          plans: filters.selectedPlans.join(","),
        }),
      };
      const response = await CommissionAPI("getLeads", [], null, queryParams);

      if (response?.data?.success && response.data.data) {
        const data = response.data.data;
        setUsers(Array.isArray(data.users) ? data.users : []);

        // Update pagination, using overridePage if provided
        setPagination((prev) => ({
          currentPage:
            overridePage ||
            Number(data.pagination?.currentPage) ||
            prev.currentPage,
          totalPages: Number(data.pagination?.totalPages) || 1,
          totalUsers: Number(data.pagination?.totalUsers) || 0,
          hasNextPage: Boolean(data.pagination?.hasNextPage),
          hasPrevPage: Boolean(data.pagination?.hasPrevPage),
        }));

        // Reset isNewSearch after successful fetch
        setIsNewSearch(false);
      } else {
        setUsers([]);
        setPagination({
          currentPage: 1,
          totalPages: 1,
          totalUsers: 0,
          hasNextPage: false,
          hasPrevPage: false,
        });
        toast.error("Invalid data received from server");
        setIsNewSearch(false);
      }
    } catch (error) {
      setUsers([]);
      setPagination({
        currentPage: 1,
        totalPages: 1,
        totalUsers: 0,
        hasNextPage: false,
        hasPrevPage: false,
      });
      toast.error("Failed to fetch users");
      console.error("Error fetching users:", error);
      setIsNewSearch(false);
    } finally {
      setIsFetching(false);
    }
  }

  // Open purchase modal
  const openPurchaseModal = (user: User) => {
    setSelectedUser(user);
    setIsPurchaseModalOpen(true);
    setUserPurchases({
      bundles: user.featuresPurchased || [],
      courses: user.courses_bundles || [],
      ownBundles: user.seperateComponents || [],
    });
  };

  // Close purchase modal
  const closePurchaseModal = () => {
    setIsPurchaseModalOpen(false);
    setSelectedUser(null);
    setUserPurchases(null);
  };

  // Open triggers modal
  const openTriggersModal = (triggers: string[]) => {
    setSelectedUserTriggers(triggers);
    setIsTriggersModalOpen(true);
  };

  const handleSearch = () => {
    if (isFetching) return;
    updateFilter("searchQuery", filters.searchInputValue?.trim() || "");
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    fetchUsers(1);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !isFetching) {
      handleSearch();
    }
  };

  const resetFilters = () => {
    if (isFetching) return;
    updateFilter("startDate", "");
    updateFilter("endDate", "");
    updateFilter("searchInputValue", "");
    updateFilter("searchQuery", "");
    updateFilter("selectedTrigger", "All");
    updateFilter("selectedTimeFilter", "All");
    updateFilter("selectedCity", "");
    updateFilter("selectedProvince", "");
    updateFilter("selectedUserType", "All");
    updateFilter("selectedPlans", []);
    setIsNewSearch(true);
    fetchUsers();
  };

  const handlePageChange = (newPage: number) => {
    if (isFetching || newPage < 1 || newPage > pagination.totalPages) return;
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
    fetchUsers(newPage);
  };

  const formatDate = (dateString: string): string => {
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      return "-";
    }
  };

  const availablePlans = plans_names.filter((plan) => plan !== "All");

  const planOptions = availablePlans.map((plan) => ({
    value: plan,
    label: plan,
  }));

  const downloadLeads = () => {
    const csvData = users.map(user => ({
      Name: user.fullname,
      Email: user.username,
      Phone: user.phonenumber,
      Location: `${user.city}, ${user.province}`,
      JoinDate: formatDate(user.accountcreateddate),
      Triggers: Array.isArray(user.triggers) ? user.triggers.join(', ') : 'N/A',
      City: user.city,
      Province: user.province,
      School: user.school,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'leads.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="w-full pb-20 px-5">
      <div className="my-5 antialiased">
        <div className="mx-auto">
          <div className="bg-white shadow-md sm:rounded-lg">
            <div className="border-b border-gray-200">
              <div className="flex items-center p-4 space-x-3">
                <div className="relative w-full flex-grow">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SearchIcon className="text-gray-400" size={20} />
                  </div>
                  <input
                    type="text"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                    placeholder="Search by name, email, or phone..."
                    value={filters.searchInputValue}
                    onChange={(e) =>
                      updateFilter("searchInputValue", e.target.value)
                    }
                    onKeyPress={handleKeyPress}
                    disabled={isFetching}
                  />
                </div>
              </div>
              <div className="flex items-end p-4 gap-5 flex-wrap">
                <CustomDropDown
                  label="Time Period"
                  options={["All", "Today", "Yesterday", "Specific"]}
                  onSelect={(selectedStatus) =>
                    updateFilter(
                      "selectedTimeFilter",
                      selectedStatus.toString()
                    )
                  }
                  width="min-w-[15rem]"
                  value={filters.selectedTimeFilter}
                  disabled={isFetching}
                />

                <CustomDropDown
                  label="Trigger"
                  options={triggers}
                  onSelect={(selectedTrigger) =>
                    updateFilter("selectedTrigger", selectedTrigger.toString())
                  }
                  width="min-w-[18rem]"
                  value={filters.selectedTrigger}
                  disabled={isFetching}
                />

                <CustomDropDown
                  label="User Type"
                  options={["Free", "All", "Paid Users"]}
                  onSelect={(userType) =>
                    updateFilter("selectedUserType", userType)
                  }
                  width="min-w-[15rem]"
                  value={filters.selectedUserType}
                  disabled={isFetching}
                />

                <div className="flex flex-col">
                  <label>City</label>
                  <input
                    type="text"
                    name={`city`}
                    placeholder="Enter City"
                    value={filters.selectedCity}
                    onChange={(e) =>
                      updateFilter("selectedCity", e.target.value)
                    }
                    className="border p-2 rounded w-full disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={isFetching}
                  />
                </div>

                <CustomDropDown
                  label="Province"
                  options={[
                    "Federally Administered Tribal Areas",
                    "Punjab",
                    "Khyber Pakhtunkhwa",
                    "Gilgit-Baltistan",
                    "Islamabad Capital Territory",
                    "Unknown",
                    "Sindh",
                    "Azad Kashmir",
                    "Balochistan",
                  ]}
                  onSelect={(province) =>
                    updateFilter("selectedProvince", province)
                  }
                  width="min-w-[18rem]"
                  value={filters.selectedProvince}
                  disabled={isFetching}
                />

                {filters.selectedTimeFilter === "Specific" && (
                  <>
                    <input
                      type="date"
                      value={filters.startDate}
                      onChange={(e) =>
                        updateFilter("startDate", e.target.value)
                      }
                      className="border p-2 rounded w-full disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={isFetching}
                    />
                    <input
                      type="date"
                      value={filters.endDate}
                      onChange={(e) =>
                        updateFilter("endDate", e.target.value)
                      }
                      className="border p-2 rounded w-full disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={isFetching}
                    />
                  </>
                )}
                <div className="flex flex-col">
                  <label>Plans</label>
                  <Select
                    isMulti
                    options={planOptions}
                    onChange={(selectedOptions) => {
                      const selectedPlans = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      updateFilter("selectedPlans", selectedPlans);
                    }}
                    placeholder="Select plans..."
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={planOptions.filter((option) =>
                      filters.selectedPlans.includes(option.value)
                    )}
                  />
                </div>
              </div>

              <div className="flex items-center space-x-3 p-4">
                <button
                  onClick={handleSearch}
                  disabled={isFetching}
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/80 transition-colors text-sm disabled:opacity-50 disabled:cursor-not-allowed min-w-[100px]"
                >
                  {isFetching ? "Searching..." : "Search"}
                </button>
                <button
                  onClick={resetFilters}
                  disabled={isFetching}
                  className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Reset
                </button>
                {users.length > 0 && (
                  <button
                    onClick={downloadLeads}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors text-sm"
                  >
                    Download Leads
                  </button>
                )}
              </div>
              {/* Total Users Count */}
              <div className="px-4 py-3 border-b border-gray-200">
                <p className="text-sm text-gray-600">
                  Total Users:{" "}
                  <span className="font-medium">{pagination.totalUsers}</span>
                </p>
              </div>

              <div className="relative min-h-[300px]">
                {isFetching && (
                  <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                    <div className="text-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mx-auto mb-2"></div>
                      <p className="text-gray-500">Fetching users...</p>
                    </div>
                  </div>
                )}
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Phone
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Location
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Join Date
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Triggers
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Purchases
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length === 0 ? (
                      <tr>
                        <td colSpan={7} className="px-4 py-12 text-center">
                          <div className="text-gray-500">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                              />
                            </svg>
                            <p className="mt-4 text-sm">No users found</p>
                            <p className="mt-2 text-xs text-gray-400">
                              Try adjusting your search or filter criteria
                            </p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      users.map((user, index) => (
                        <tr
                          key={user._id || index}
                          className="border-b hover:bg-gray-50"
                        >
                          <td className="px-4 py-3">{user.fullname || "-"}</td>
                          <td className="px-4 py-3">{user.username || "-"}</td>
                          <td className="px-4 py-3">{user.phonenumber || "-"}</td>
                          <td className="px-4 py-3">
                            {user.city && user.province
                              ? `${user.city}, ${user.province}`
                              : user.city || user.province || "-"}
                          </td>
                          <td className="px-4 py-3">
                            {formatDate(user.accountcreateddate)}
                          </td>
                          <td className="px-4 py-3">
                            {Array.isArray(user.triggers) &&
                            user.triggers.length > 0 ? (
                              user.triggers.length > 5 ? (
                                <button
                                  onClick={() => openTriggersModal(user.triggers)}
                                  className="text-blue-500 underline"
                                >
                                  View Triggers
                                </button>
                              ) : (
                                user.triggers.map((trigger, index) => (
                                  <span
                                    key={index}
                                    className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                                  >
                                    {trigger || "Unknown"}
                                  </span>
                                ))
                              )
                            ) : (
                              <span className="text-gray-400 text-xs">
                                No triggers
                              </span>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <button
                              onClick={() => openPurchaseModal(user)}
                              className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm"
                            >
                              View Purchases
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {/* Triggers Modal */}
              {isTriggersModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white p-5 rounded shadow-lg max-w-3xl space-y-3">
                    <h2 className="text-lg font-semibold">User Triggers</h2>
                    <div className="flex flex-wrap gap-2">
                      {selectedUserTriggers.map((trigger, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                        >
                          {trigger}
                        </span>
                      ))}
                    </div>
                    <button
                      onClick={() => setIsTriggersModalOpen(false)}
                      className="mt-4 bg-primary text-white px-4 py-2 rounded float-right"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {/* Pagination */}
              <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200">
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing page{" "}
                      <span className="font-medium">
                        {pagination.currentPage}
                      </span>{" "}
                      of{" "}
                      <span className="font-medium">{pagination.totalPages}</span>{" "}
                      ({" "}
                      <span className="font-medium">{pagination.totalUsers}</span>{" "}
                      total users)
                    </p>
                  </div>
                  <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                      <button
                        onClick={() =>
                          handlePageChange(pagination.currentPage - 1)
                        }
                        disabled={!pagination.hasPrevPage || isFetching}
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Previous
                      </button>
                      <button
                        onClick={() =>
                          handlePageChange(pagination.currentPage + 1)
                        }
                        disabled={!pagination.hasNextPage || isFetching}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        Next
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* User Purchases Modal */}
      {isPurchaseModalOpen && (
        <UserPurchasesModal
          selectedUser={selectedUser}
          closePurchaseModal={closePurchaseModal}
          userPurchases={userPurchases}
        />
      )}
    </div>
  );
};

export default UserFilterScreen;
