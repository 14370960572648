import React, { useState, useEffect, useCallback } from "react";
import { FlashcardAPI } from "(apis)/(shared-apis)/flashcard-api";
import { Edit, Eye } from "lucide-react";
import { QuestionModal } from "../flashcards_questions/components/QuestionModal";
import toast, { Toaster } from "react-hot-toast";

export default function Page() {
  const [flashcardsReport, setFlashcardsReport] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [uniqueStatuses, setUniqueStatuses] = useState(["ALL"]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [report_id, setReport_id] = useState(null);
  const [newQuestion, setNewQuestion] = useState({
    question_text: "",
    answer_text: "",
    extra: "",
    description: "",
    reference: "",
    tags: [],
    image: null,
    difficulty: 1,
  });
  const [loadingStates, setLoadingStates] = useState({
    updating: false,
    fetching: false,
    updating_report:false
  });

  useEffect(() => {
    const fetchFlashcardsReport = async () => {
      setLoadingStates((prev) => ({ ...prev, fetching: true }));
      try {
        const response = await FlashcardAPI("getFlashcardsReport");
        if (response?.data?.statusCode === 200) {
          setFlashcardsReport(response.data.data || []);
          const statuses = [
            "ALL",
            ...Array.from(
              new Set(response.data.data?.map((report) => report.status) || [])
            ),
          ];
          setUniqueStatuses(statuses as string[]);
        }
      } catch (error) {
        console.error("Error fetching flashcards report:", error);
        toast.error("Failed to fetch flashcards report");
      } finally {
        setLoadingStates((prev) => ({ ...prev, fetching: false }));
      }
    };
    fetchFlashcardsReport();
  }, []);

  const handleQuestionChange = useCallback((updater) => {
    setNewQuestion((prev) => {
      const next = typeof updater === "function" ? updater(prev) : updater;
      return next;
    });
  }, []);

  const validateQuestion = (question) => {
    const strippedQuestionText = question.question_text
      ?.replace(/<[^>]*>?/gm, "")
      .trim();
    if (!strippedQuestionText) {
      toast.error("Question text is required");
      return false;
    }

    const strippedAnswerText = question.answer_text
      ?.replace(/<[^>]*>?/gm, "")
      .trim();
    if (!strippedAnswerText) {
      toast.error("Answer text is required");
      return false;
    }

    return true;
  };

  const handleUpdateQuestion = async () => {
    if (!report_id) {
      toast.error("Report ID is required.");
      return;
    }
    if (!validateQuestion(newQuestion)) return;
    setLoadingStates((prev) => ({ ...prev, updating: true }));
    try {
      const formData = new FormData();

      formData.append("question_text", newQuestion.question_text);
      formData.append("answer_text", newQuestion.answer_text);
      formData.append("description", newQuestion.description || "");
      formData.append("reference", newQuestion.reference || "");
      formData.append("extra", newQuestion.extra || "");
      formData.append("difficulty", String(newQuestion.difficulty || 1));
      formData.append("tags", JSON.stringify(newQuestion.tags || []));

      if (
        newQuestion.image instanceof File ||
        newQuestion.image instanceof Blob
      ) {
        formData.append("image", newQuestion.image);
      } else {
        formData.append("imageUrl", newQuestion.image || "");
      }

      if (report_id) {
        formData.append("report_id", report_id);
        formData.append("report_status", "RESOLVED");
      }
      const response = await FlashcardAPI("updateQuestion", formData, [
        selectedQuestion._id,
      ]);

      if (response?.data?.success) {
        toast.success("Question updated successfully");
        setNewQuestion({
          question_text: "",
          answer_text: "",
          extra: "",
          description: "",
          reference: "",
          tags: [],
          image: null,
          difficulty: 1,
        });
        setIsEditModalOpen(false);
        setSelectedQuestion(null);
        const reportResponse = await FlashcardAPI("getFlashcardsReport");
        if (reportResponse.data.statusCode === 200) {
          setFlashcardsReport(reportResponse.data.data);
        }
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, updating: false }));
    }
  };

  const filteredReports = flashcardsReport.filter((report) =>
    selectedStatus === "ALL" ? true : report.status === selectedStatus
  );

  const handleViewDetails = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const handleReportReject = async () => {
    if (!report_id) {
      toast.error("Report not found");
      return;
    }
    try {
      const response = await FlashcardAPI(
        "changeFlashcardsReportStatus",
        {
          report_id,
          status: "REJECTED",
        },
        [report_id]
      );
      if (response?.data?.success) {
        toast.success("Report rejected successfully");
        const reportResponse = await FlashcardAPI("getFlashcardsReport");
        if (reportResponse.data.statusCode === 200) {
          setFlashcardsReport(reportResponse.data.data);
        }
      }
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setLoadingStates((prev) => ({ ...prev, updating: false }));
      setIsModalOpen(false);
      setSelectedReport(null);
      setNewQuestion({
        question_text: "",
        answer_text: "",
        extra: "",
        description: "",
        reference: "",
        tags: [],
        image: null,
        difficulty: 1,
      });
    }
  };
  return (
    <div className="w-[95%] mx-auto pb-20 px-5">
      <Toaster />
      <div className="flex items-center justify-between py-4 border-b border-gray-200 mb-4">
        <h2 className="text-2xl font-bold text-gray-700">Flashcards Report</h2>
        <div className="flex space-x-2">
          {uniqueStatuses.map((status) => (
            <button
              key={status}
              onClick={() => setSelectedStatus(status)}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedStatus === status
                  ? "bg-primary-500 text-white"
                  : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-100"
              }`}
            >
              {status}
            </button>
          ))}
        </div>
      </div>

      <div className="bg-white shadow-md sm:rounded-lg">
        {loadingStates.fetching ? (
          <div className="animate-pulse">
            <div className="overflow-x-auto">
              <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-4">
                      Created At
                    </th>
                    <th scope="col" className="px-4 py-4">
                      Updated At
                    </th>
                    <th scope="col" className="px-4 py-4 text-left">
                      User Name
                    </th>
                    <th scope="col" className="px-4 py-4 text-left">
                      Question
                    </th>
                    <th scope="col" className="px-4 py-4 text-center">
                      Report Text
                    </th>
                    <th scope="col" className="px-4 py-3 text-center">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-3 text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(5)].map((_, index) => (
                    <tr key={index} className="bg-white border-b">
                      <td className="px-4 py-3">
                        <div className="h-4 bg-gray-200 rounded w-24"></div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="h-4 bg-gray-200 rounded w-24"></div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="h-4 bg-gray-200 rounded w-32"></div>
                      </td>
                      <td className="px-4 py-3">
                        <div className="h-4 bg-gray-200 rounded w-48"></div>
                      </td>
                      <td className="px-4 py-3 text-center">
                        <div className="h-4 bg-gray-200 rounded w-36 mx-auto"></div>
                      </td>
                      <td className="px-4 py-3 text-center">
                        <div className="h-4 bg-gray-200 rounded w-20 mx-auto"></div>
                      </td>
                      <td className="px-4 py-3 text-center">
                        <div className="flex items-center justify-center gap-2">
                          <div className="h-6 w-6 bg-gray-200 rounded"></div>
                          <div className="h-6 w-6 bg-gray-200 rounded"></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : filteredReports.length === 0 ? (
          <div className="text-center p-8 text-gray-500">No reports found</div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full mb-20 text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-4">
                    Created At
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Updated At
                  </th>
                  <th scope="col" className="px-4 py-4 text-left">
                    User Name
                  </th>
                  <th scope="col" className="px-4 py-4 text-left">
                    Question
                  </th>
                  <th scope="col" className="px-4 py-4 text-center">
                    Report Text
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.map((report, index) => (
                  <tr
                    key={report._id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-4 py-3">
                      {new Date(report.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-3">
                      {new Date(report.updatedAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-3">
                      {report?.user?.fullname || "N/A"}
                    </td>
                    <td className="px-4 py-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: report?.question_id?.question_text || "N/A",
                        }}
                        className="max-w-md overflow-hidden text-ellipsis"
                      />
                    </td>
                    <td className="px-4 py-3 text-center">
                      {report?.report_text || "N/A"}
                    </td>
                    <td className="px-4 py-3 text-center">
                      <span
                        className={`px-2 py-1 rounded-full font-semibold text-xs ${
                          report.status === "PENDING"
                            ? "bg-yellow-100 text-yellow-800"
                            : report.status === "RESOLVED"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {report.status}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-center">
                      <div className="flex items-center justify-center gap-2">
                        <button
                          onClick={() => handleViewDetails(report)}
                          className="text-emerald-600 hover:text-emerald-800 font-medium"
                        >
                          <Eye />
                        </button>
                        <Edit
                          className="w-5 h-5 text-gray-500 cursor-pointer"
                          onClick={() => {
                            const questionToEdit = {
                              ...report.question_id,
                              image: report.question_id.image || null,
                              tags: report.question_id.tags || [],
                              difficulty:
                                Number(report.question_id.difficulty) || 1,
                              description: report.question_id.description || "",
                              reference: report.question_id.reference || "",
                              extra: report.question_id.extra || "",
                            };
                            setReport_id(report?._id);
                            setSelectedQuestion(report.question_id);
                            setNewQuestion(questionToEdit);
                            setIsEditModalOpen(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {isModalOpen && selectedReport && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-gray-900">
                Report Details
              </h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <h4 className="font-semibold text-gray-700">
                  User Information
                </h4>
                <p>Name: {selectedReport?.user?.fullname || "N/A"}</p>
                <p>Email: {selectedReport?.user?.username || "N/A"}</p>
                <p>Phone: {selectedReport?.user?.phonenumber || "N/A"}</p>
              </div>

              <div>
                <h4 className="font-semibold text-gray-700">
                  Question Details
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedReport?.question_id?.question_text || "N/A",
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedReport?.question_id?.answer_text || "N/A",
                  }}
                />
                <p>
                  Reference: {selectedReport?.question_id?.reference || "N/A"}
                </p>
              </div>

              <div>
                <h4 className="font-semibold text-gray-700">
                  Report Information
                </h4>
                <p>Report Text: {selectedReport?.report_text}</p>
                <p>Status: {selectedReport?.status}</p>
                <p>Issues: {selectedReport?.issues?.join(", ") || "N/A"}</p>
                <p>
                  Created: {new Date(selectedReport.createdAt).toLocaleString()}
                </p>
                <p>
                  Updated: {new Date(selectedReport.updatedAt).toLocaleString()}
                </p>
              </div>
            </div>

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <QuestionModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        question={newQuestion}
        onQuestionChange={handleQuestionChange}
        onSave={handleUpdateQuestion}
        isLoading={loadingStates.updating || loadingStates.updating_report}
        isEditing={true}
        report_page={true}
        on_report_reject={handleReportReject}
        report_status={selectedReport?.status}
      />
    </div>
  );
}
