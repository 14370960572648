import { useCallback, useEffect, useState } from "react";
import { Button, Input, Menu, Select } from "antd";
import { backendUrl } from "../../constants/config";
import GlobalTable from "../../shared-components/table/GlobalTable";
import ViewScholarship from "./ViewScholarship";
import { formatNumberWithCommas } from "shared-pages/latest-commission/utils";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import { apiRequest } from "(apis)/api-interface/api-handler";
interface DataType {
  _id: string;
  Fname: string;
  Lname: string;
  Email: string;
  PhoneNumber: string;
  Status: string;
  CreatedAt: string;
  Amount: number;
  type: string;
}
const ScholarShip: React.FC = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [clickedInfoData, setClickedINfoData] = useState<{}>({});
  const [filteredData, setFilteredData] = useState<DataType[]>([]);
  const [courses, setCourses] = useState<any[]>([]);
  // New state for search and pagination
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 30;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const tabs = ["Pending", "Approved", "Rejected", "All"];
  const [selectedTab, setSelectedTab] = useState<string>("Pending");
  const [selectedType, setSelectedType] = useState<string>("All");

  useEffect(() => {
    fetchData();
    fetchLiveCourses();
  }, []);

  // Modified getUniqueTypes function
  const getUniqueTypes = () => {
    const types = new Set(data.map(item => item.type || "Bundle")); // Set default to "Bundle"
    return ["All", ...Array.from(types)].filter(Boolean);
  };

  // Modify the filtering logic in useEffect
  useEffect(() => {
    let result = data;

    // Filter by status
    if (selectedTab !== "All") {
      result = result.filter((item) => item.Status === selectedTab);
    }

    // Filter by type, considering "Bundle" as default
    if (selectedType !== "All") {
      result = result.filter((item) => (item.type || "Bundle") === selectedType);
    }

    // Filter by search term
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      result = result.filter(
        (item) =>
          item.Email.toLowerCase().includes(lowercasedTerm) ||
          `${item.Fname} ${item.Lname}`.toLowerCase().includes(lowercasedTerm)
      );
    }

    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, selectedTab, selectedType, data]);

  // Pagination logic
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendUrl}/PendingApplication`);
      const jsonData = await response.json();
      setData(jsonData);
      setFilteredData(
        jsonData.filter((item: any) => item.Status === "Pending")
      );
      setCurrentPage(1);
      setLoading(false);
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const fetchLiveCourses = async () => {
    try {
      const res = await apiRequest("getCourses", null, []);
      if (res?.status === 200) {
        const published_live_courses = res?.data?.courses
          ?.filter((course: any) => course?.isPublish === true)
          ?.map((course: any) => {
            return {
              ...course,
              thumbnail_image:
                course?.thumbnail_image ||
                course?.course_image ||
                "/default-course-image.png",
              title: course?.title || course?.courseName || "Untitled Course",
              subject: course.subject || "General",
              tags:
                course?._id === "677e984bc6b7a3d9e214f01b"
                  ? ["MDCAT", "FSC Parallel Prep"]
                  : course.tags || [],
              is_live: true,
            };
          });

        setCourses(published_live_courses.reverse() || []);
      }
    } catch (error) {
      console.error("Error fetching live courses:", error);
    }
  };

  const GetDate = (date: string) => {
    //yyyy-mm-dd
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    if (tab === "Pending") {
      setFilteredData(data.filter((item) => item.Status === "Pending"));
      setCurrentPage(1);
    } else if (tab === "Approved") {
      setFilteredData(data.filter((item) => item.Status === "Approved"));
      setCurrentPage(1);
    } else if (tab === "Rejected") {
      setFilteredData(data.filter((item) => item.Status === "Rejected"));
      setCurrentPage(1);
    } else if (tab === "All") {
      setFilteredData(data);
      setCurrentPage(1);
    }
  };

  // Render statistics card
  const StatCard = ({ title, value }: { title: string; value: number }) => (
    <div className="p-4 rounded-lg shadow-2xl flex flex-col gap-2">
      <h2 className="font-bold text-lg text-gray-500">{title}</h2>
      <p className="text-3xl font-bold text-gray-700">
        {formatNumberWithCommas(value?.toFixed())}
      </p>
    </div>
  );

  // Pagination controls
  const renderPagination = () => (
    <div className="flex justify-center items-center my-4 space-x-2">
      <Button
        onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        onClick={() => setCurrentPage((prev) => Math.min(totalPages, prev + 1))}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
    </div>
  );

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
        <StatCard title="Total Applications" value={data.length} />
        <StatCard
          title="Pending Applications"
          value={data.filter((item) => item.Status === "Pending").length}
        />
        <StatCard
          title="Accepted Applications"
          value={data.filter((item) => item.Status === "Approved").length}
        />
        <StatCard
          title="Rejected Applications"
          value={data.filter((item) => item.Status === "Rejected").length}
        />
      </div>

      {/* Add Type Filter before the search input */}
      <div className="mb-4 mt-4">
        <Select
          style={{ width: "25%" }}
          placeholder="Select Type"
          value={selectedType}
          onChange={(value) => setSelectedType(value)}
        >
          {getUniqueTypes().map((type) => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Search Input */}
      <div className="mb-4 mt-4">
        <Input
          placeholder="Search by Name or Email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full max-w-md"
        />
      </div>

      <section className="my-5 antialiased flex gap-2 flex-wrap justify-center">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${
              selectedTab === tab
                ? "bg-primary text-white"
                : "bg-white border border-gray-100 text-gray-700"
            } cursor-pointer font-bold shadow-2xl px-4 py-1 rounded-md`}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </div>
        ))}
      </section>

      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4 text-left">SR#</th>
              <th scope="col" className="px-4 py-4 text-left">Full Name</th>
              <th scope="col" className="px-4 py-4 text-left">Email</th>
              <th scope="col" className="px-4 py-4 text-left">Mobile Number</th>
              <th scope="col" className="px-4 py-4 text-left">Status</th>
              <th scope="col" className="px-4 py-4 text-left">Type</th>
              <th scope="col" className="px-4 py-4 text-left">Date of Application</th>
              {/* {selectedTab === "Pending" && ( */}
                <th scope="col" className="px-4 py-3 text-left">Action</th>
              {/* )} */}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((order, index) => (
              <tr className="border-b" key={order._id}>
                <td className="px-4 py-3 text-left">{index + 1}</td>
                <td className="px-4 py-3 text-left">
                  {order.Fname} {order.Lname}
                </td>
                <td className="px-4 py-3 text-left">{order.Email}</td>
                <td className="px-4 py-3 text-left">{order.PhoneNumber}</td>
                <td className="px-4 py-3 text-left">{order.Status}</td>
                <td className="px-4 py-3 text-left">
                  {order.type || "Bundle"}
                </td>
                <td className="px-4 py-3 text-left">
                  {GetDate(order.CreatedAt)}
                </td>
                {/* {selectedTab === "Pending" && ( */}
                  <td className="px-4 py-3 text-left">
                    <button
                      className="bg-primary text-white px-4 py-1 rounded-md text-xs font-bold hover:scale-105 transition-all ease-in-out cursor-pointer"
                      onClick={() => {
                        setClickedINfoData(order);
                        setIsOpen(true);
                      }}
                    >
                      View
                    </button>
                  </td>
                {/* )} */}
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        {renderPagination()}

        <ViewScholarship
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          clickedInfoData={clickedInfoData}
          fetchData={fetchData}
          courses={courses}
        />
      </div>
    </div>
  );
};

export default ScholarShip;
