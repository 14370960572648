import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Select } from "antd";
import { ButtonFill, ButtonOutlined } from "shared-components/ui/CustomButtons";
import { FileUpload } from "shared-components/ui/file-upload";
import {
  useTextbookModal,
  SUBJECTS,
  PROVINCES,
  YEARS,
  ACCESS_TYPES,
} from "./useTextbookModal";
import { Input } from "shared-components/ui/CustomForm";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";

interface ModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  initialData?: any; // Optional initial data for editing
  onSubmit?: (data: any) => Promise<void>; // Optional custom submit handler
}

const Modal: React.FC<ModalProps> = ({
  isModalOpen,
  closeModal,
  initialData = null,
  onSubmit,
}) => {
  const {
    loading,
    data,
    formData,
    setFormData,
    handleFileSelect,
    handleSubmit,
    isEditMode,
  } = useTextbookModal({
    initialData,
    closeModal,
    onSubmit: onSubmit || undefined,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [subjectsData, setSubjectsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchUniqueSubjects = async () => {
    try {
      setIsLoading(true);
      const response = await BasicGetApi("getUniqueSubjects");

      if (response) {
        // console.log("Response: ", response);
        const apiData =
          response?.data?.data && response?.data?.data?.length > 0
            ? response?.data?.data
            : [];
        setSubjectsData(apiData);
      }
    } catch (error) {
      console.error("There was an error fetching the questions!", error);
    } finally {
      setIsLoading(false);
    }
  };
  console.log("subjectsData: ", subjectsData);
  useEffect(() => {
    fetchUniqueSubjects();
  }, []);

  if (!isModalOpen) return null;

  return (
    <>
      <Toaster />
      <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000] flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm">
        <div className="bg-white rounded-lg p-8 w-full max-h-[90%] overflow-y-auto max-w-4xl mx-4">
          <div className="flex justify-between items-center border-b pb-4 mb-6">
            <h2 className="text-2xl font-bold text-gray-800">
              {isEditMode ? "Edit Textbook" : "Add Textbook"}
            </h2>
            <button
              onClick={closeModal}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center h-40">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="Name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <Input
                    type="text"
                    name="name"
                    value={formData?.name}
                    onChange={(name, value) => {
                      setFormData({ ...formData, name: value });
                    }}
                    placeholder="Enter Name"
                    isError={false}
                    errorMessage={""}
                  />
                </div>

                {/* Board Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Boards <span className="text-red-500">*</span>
                  </label>
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Select Boards"
                    value={formData?.boards || []}
                    onChange={(values) =>
                      setFormData({ ...formData, boards: values })
                    }
                    size="large"
                    className="w-full"
                    options={data.boardsData}
                    filterOption={(input, option) =>
                      String(option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </div>

                {/* Subject Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Subject <span className="text-red-500">*</span>
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Subject"
                    value={formData.subject}
                    onChange={(value) =>
                      setFormData({ ...formData, subject: value })
                    }
                    size="large"
                    className="w-full"
                    options={subjectsData?.map((subject) => ({
                      label: subject,
                      value: subject,
                    }))}
                  />
                </div>

                {/* Province Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Province <span className="text-red-500">*</span>
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Province"
                    value={formData.province}
                    onChange={(value) =>
                      setFormData({ ...formData, province: value })
                    }
                    size="large"
                    className="w-full"
                    options={PROVINCES}
                  />
                </div>

                {/* Year Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Year <span className="text-red-500">*</span>
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Year"
                    value={formData.year}
                    onChange={(value) =>
                      setFormData({ ...formData, year: value })
                    }
                    size="large"
                    className="w-full"
                    options={YEARS}
                  />
                </div>

                {/* Access Type Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Access Type <span className="text-red-500">*</span>
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Access Type"
                    value={formData.access}
                    onChange={(value) =>
                      setFormData({ ...formData, access: value })
                    }
                    size="large"
                    className="w-full"
                    options={ACCESS_TYPES}
                  />
                </div>

                {/* Topic Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Topic <span className="text-red-500">*</span>
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Topic"
                    value={formData.topicName}
                    onChange={(value) => {
                      // Reset subtopic when topic changes
                      setFormData({
                        ...formData,
                        topicName: value,
                        subTopicName: null,
                      });
                    }}
                    size="large"
                    className="w-full"
                    filterOption={(input, option) =>
                      String(option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    options={data?.topicsData?.map((topic: any) => ({
                      label: `${topic?.topicName} ${
                        topic?.Subject || ""
                      }`?.trim(),
                      value: topic?.topicName,
                    }))}
                  />
                </div>

                {/* Sub Topic Dropdown */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-600">
                    Sub Topic
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Sub Topic"
                    value={formData.subTopicName}
                    onChange={(value) =>
                      setFormData({ ...formData, subTopicName: value })
                    }
                    size="large"
                    className="w-full"
                    disabled={!formData.topicName}
                    filterOption={(input, option) =>
                      String(option?.label ?? "")
                        .toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    options={
                      data?.topicsData
                        ?.find(
                          (topic) => topic?.topicName === formData?.topicName
                        )
                        ?.subTopics?.map((subTopic) => ({
                          label: subTopic?.subTopicName,
                          value: subTopic?.subTopicName,
                        })) || []
                    }
                  />
                </div>

                <div className="flex items-center space-x-3 w-full ">
                  <CustomDropDown
                    label="Select Category"
                    options={["PRE-MEDICAL", "MEDSCHOOL"]}
                    onSelect={(selectedCategory) =>
                      setFormData({
                        ...formData,
                        category: selectedCategory,
                      })
                    }
                    width="w-full"
                    value={formData?.category}
                  />
                </div>

                <div className="grid md:grid-cols-2 gap-4 md:col-span-2">
                  {/* Marketing and Publishing Status */}
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={formData.isFreeForMarketing}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            isFreeForMarketing: e.target.checked,
                          })
                        }
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      <span className="ms-3 text-sm font-medium text-gray-700">
                        Free for Marketing
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={formData.isPublished}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            isPublished: e.target.checked,
                          })
                        }
                      />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      <span className="ms-3 text-sm font-medium text-gray-700">
                        Published
                      </span>
                    </label>
                  </div>

                  {/* thumbnailImageUrl Upload */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-600">
                      Thumbnail Image <span className="text-red-500">*</span>
                    </label>
                    <FileUpload
                      onFileSelect={(file) =>
                        handleFileSelect("thumbnailImageUrl", file)
                      }
                      accept="image/*"
                      maxSize={2 * 1024 * 1024} // 2MB
                      previewType="image"
                      value={
                        typeof formData.thumbnailImageUrl === "string"
                          ? formData.thumbnailImageUrl
                          : undefined
                      }
                    />
                  </div>

                  {/* PDF Upload */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-600">
                      PDF File <span className="text-red-500">*</span>
                    </label>
                    <FileUpload
                      onFileSelect={(file) => handleFileSelect("pdf", file)}
                      accept=".pdf"
                      maxSize={20 * 1024 * 1024} // 20MB
                      previewType="pdf"
                      value={
                        formData.pdfUrl ||
                        (typeof formData.pdf === "string"
                          ? formData.pdf
                          : undefined)
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Submit and Cancel Buttons */}
              <div className="flex justify-end items-center space-x-4 pt-6 mt-6 border-t">
                <ButtonOutlined
                  type="button"
                  handleClick={closeModal}
                  disabled={isSubmitting}
                >
                  Cancel
                </ButtonOutlined>
                <ButtonFill type="Submit" disabled={isSubmitting}>
                  {isSubmitting
                    ? isEditMode
                      ? "Updating..."
                      : "Saving..."
                    : isEditMode
                    ? "Update"
                    : "Add"}
                </ButtonFill>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
