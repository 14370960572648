import { X } from "@mui/icons-material";

const FullScreenPopup = ({ popup, setPopup }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white rounded-xl shadow-2xl max-w-md w-full p-8 text-center relative">
        {popup?.canDestroy && (
          <div className="absolute top-0 right-0 p-4 opacity-50">
            <button
              onClick={() =>
                setPopup({
                  isOpen: true,
                  message: null,
                  canDestroy: false,
                  heading: null,
                })
              }
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        )}

        <div className="mb-6">
          <img
            src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/public/icons/ErrorIcon.svg"
            className="h-16 w-16 mx-auto mb-4"
            alt="Error"
          />
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            {popup?.heading}
          </h2>
          <p className="text-gray-600 mb-4">{popup?.message}</p>
          <p className="text-sm text-gray-500 italic mb-6">
            Please try again or contact support if the issue persists.
          </p>
        </div>

        <div className="animate-pulse">
          <div className="h-2 bg-gray-300 rounded mb-2"></div>
          <div className="h-2 bg-gray-300 rounded w-3/4 mx-auto"></div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenPopup;
