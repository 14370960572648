import { apiRequest } from "(apis)/api-interface/api-handler"; 
import { message } from 'antd'; 
import React, { useEffect, useState } from 'react';
import GlobalTable from 'shared-components/table/GlobalTable';
import { ManageIcon } from 'shared-components/ui/Icons';

interface Question {
  key: string; 
  questionId: string;
  updatedAt: string;
}

const AuditQuestion: React.FC = () => {
  const [data, setData] = useState<Question[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await apiRequest("getAuditedQuestions");
        if (res?.data?.isSuccess) {
          const sortedData = res?.data?.questions?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
          setData(sortedData);
        } else {
          message.error('Failed to fetch questions.');
        }
      } catch (error) {
        message.error('An error occurred while fetching questions.');
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchData();
  }, []);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  const columns = [
    {
      title: 'Question ID',  
      dataIndex: 'questionId',  
      key: 'questionId',   
    },
    // {
    //   title: 'Reported On',  
    //   dataIndex: 'createdAt',  
    //   key: 'createdAt',  
    //   render: (row: string) => (
    //     <div>{formatDate(row)}</div>
    //   ),
    // },
    {
      title: 'Last Updated',  
      dataIndex: 'updatedAt',  
      key: 'updatedAt',  
      render: (row: string) => (
        <div>{formatDate(row)}</div>
      ),
    },
    {
      title: 'View History',   
      key: 'actions',
      render: (row: Question) => (
        <div>
          <a target='_blank' rel="noreferrer" href={`/manage/audit-question/${row.questionId}`}><ManageIcon/></a>
        </div>
      ),
    }
  ];
 
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Audit Questions</h2>
      {/* Ant Design Table */}
      <GlobalTable
        columns={columns}
        data={data}
        loading={loading}  // Show loading spinner while data is fetching
       
      />
    </div>
  );
};

export default AuditQuestion;
