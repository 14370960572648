import { apiRequest } from "(apis)/api-interface/api-handler";
import { ConfigProvider, message, Select, Tabs, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import useGetData from "shared-components/custom-hook/get-data";
import Loader from "shared-components/Loader";
import { UserContext } from "state-management/user-context";
import { Report } from "./types";
import { useNavigate } from "react-router-dom";
const statusOptions = [
  {
    value: "Solved",
    label: "Solved",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Rejected",
    label: "Rejected",
  },
];
// types.d.ts or a similar file

type ReportsResponse = {
  reports: Report[];
};

const VaultReportedIssues = () => {
  const { data, loading, fetchData, setData } =
    useGetData<ReportsResponse>("getVaultIssue");
  const reportsData = data?.reports;
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedGeneralStatus, setSelectedGeneralStatus] = useState<
    string | null
  >(null);
  const [filteredReports, setFilteredReports] = useState<Report[]>([]);
  const { user } = useContext(UserContext);
  const [selectedIssue, setSelectedIssue] = useState<Report | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  console.log("user", user);
  useEffect(() => {
    if (data?.reports) {
      const filteredReports = selectedType
        ? reportsData.filter((report) => report.type === selectedType)
        : reportsData;
      setFilteredReports(filteredReports);
    }
  }, [data, selectedType]);

  const navigate = useNavigate();
  // Filter reports by selected type, or show all if no type is selected

  // Define tabs
  const tabItems = [
    { key: "all", label: "All" },
    { key: "notes", label: "Notes" },
    { key: "guides", label: "Guides" },
    { key: "usmle-guides", label: "USMLE Guides" },
    { key: "essentials", label: "Essentials" },
    { key: "cheatsheets", label: "Cheatsheets" },
    { key: "shortlistings", label: "Shortlistings" },
  ];
  const filteredTabItems = process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
  ? tabItems.filter(item => ["all", "guides", "usmle-guides"].includes(item.key))
  : tabItems.filter(item => item.key !== "usmle-guides");
  const handleGeneralStatusFilter = (status: string) => {
    setSelectedGeneralStatus(status);
    const filteredByType = selectedType
      ? data.reports.filter((report) => report.type === selectedType)
      : data.reports;

    setFilteredReports(
      status === "all"
        ? selectedType === null
          ? data.reports
          : data.reports.filter((report) => report.type === selectedType)
        : filteredByType.filter((report) => report.status === status)
    );
  };

  // Handle tab change
  const handleTabChange = (key: string) => {
    setSelectedGeneralStatus(null);
    setSelectedType(key === "all" ? null : key); // Set to null for "All"
  };
  const handleChangeStatus = async (status: string, id: string) => {
    try {
      const res = await apiRequest(
        "UpdateVaultIssueStatus",
        { status, userEmail: user.username },
        [id]
      );
      if (res?.data?.isSuccess) {
        setSelectedGeneralStatus(null);
        message.success(res?.data?.message);
        const updatedReport = res?.data?.report;
        setData((prevReportsData) => {
          return {
            ...prevReportsData,
            reports: [
              updatedReport,
              ...prevReportsData.reports.filter((report) => report._id !== id),
            ],
          };
        });
      }
    } catch (error) {}
  };

  const showIssueDetails = (issue: Report) => {
    setSelectedIssue(issue);
    setIsModalVisible(true);
  };

  return (
    <div>
      <Loader loading={loading} />
      <div className="flex flex-col md:flex-row  justify-between mr-10 w-full mb-5">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemActiveColor: "red",
                horizontalItemPadding: "10px",
                inkBarColor: "white",
                verticalItemPadding: "0px",
                cardPadding: "0px",
              },
            },
          }}
        >
          <Tabs className="w-full md:w-2/3" defaultActiveKey="all" onChange={handleTabChange}>
            {filteredTabItems?.map((tab) => (
              <Tabs.TabPane
                className="border-blue-200"
                tab={tab.label}
                key={tab.key}
              />
            ))}
          </Tabs>
        </ConfigProvider>
        <div className="md:mr-10">
          <Select
            className="w-full md:w-32"
            options={[{ label: "All", value: "all" }, ...statusOptions]}
            placeholder="Filter Status"
            onChange={(val) => handleGeneralStatusFilter(val)}
            value={selectedGeneralStatus}
          />
        </div>
      </div>
      <div className=" bg-white shadow-md sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="w-full mb-20 text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-4">
                  SR No.
                </th>
                <th scope="col" className="px-4 py-4">
                  _id
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Description
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Reported By
                </th>
                <th scope="col" className="px-4 py-4 text-center">
                  Resourse ID
                </th>
                { selectedType === null &&   <th scope="col" className="px-4 py-4 text-center">
                  Type
                </th>}
                <th scope="col" className="px-4 py-4 text-center">  
                  URL
                </th>

                <th scope="col" className="px-4 py-3 text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredReports &&
                filteredReports?.map((pack: any, index: number) => (
                  <tr className="border-b" key={pack?._id}>
                    <td className="px-4 py-4">{index + 1}</td>
                    <td className="px-4 py-4">{pack?._id}</td>
                    <td className="px-4 py-3 text-left">{pack?.description}</td>
                    <td className="px-4 py-3 text-center">
                      {pack?.reportedBy}
                    </td>
                    {/* <td className="px-4 py-3 text-center">{pack?.user}</td> */}
                    <td className="px-4 py-3 text-center">{pack?.url?.split('/').pop()}</td>
                 { selectedType === null &&    <td className="px-4 py-3 text-center">{pack?.type?.charAt(0).toUpperCase() + pack?.type?.slice(1)}</td>}
                 <td className="px-4 py-3 text-left">
                      <button 
                        onClick={() => {
                          window.open(`https://${process.env.REACT_APP_CATEGORY === "MEDSCHOOL" ? "medschool.pk" : "staging.premed.pk"}${getUrl(pack?.type, pack?._id)}`, '_blank');
                        }}
                      >
                        Open
                      </button>
                    </td>
                    <td className="px-4 py-3 text-center">
                      <div className="flex gap-2 justify-center items-center">
                        <button
                          className="bg-blue-500 text-white px-2 py-1 rounded"
                          onClick={() => showIssueDetails(pack)}
                        >
                          View
                        </button>
                        <Select
                          value={pack?.status}
                          onChange={(status) => {
                            handleChangeStatus(status, pack?._id);
                          }}
                          placeholder="Change Status "
                          options={statusOptions}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        title="Issue Details"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedIssue && (
          <div className="space-y-4">
            <div>
              <span className="font-semibold">Description:</span> {selectedIssue.description}
            </div>
            <div>
              <span className="font-semibold">Reported By:</span> {selectedIssue.reportedBy}
            </div>
            <div>
              <span className="font-semibold">Status:</span> {selectedIssue.status}
            </div>
            {selectedIssue.page_number && (
              <div>
                <span className="font-semibold">Page Number:</span> {selectedIssue.page_number}
              </div>
            )}
            {selectedIssue.issues && (
              <div>
                <span className="font-semibold">Issues:</span>{" "}
                {selectedIssue.issues.join(", ")}
              </div>
            )}
            <div>
              <span className="font-semibold">Report Date:</span>{" "}
              {new Date(selectedIssue.reportDate).toLocaleDateString()}
            </div>
            <div>
              <span className="font-semibold">Solved Date:</span>{" "}
              {new Date(selectedIssue.solvedDate).toLocaleDateString()}
            </div>
            <div>
              <span className="font-semibold">ID:</span>{" "}
              {/*FROM URL GET THE LAST / AND REMOVE THE LAST /*/}
              {selectedIssue.url.split('/').pop()}
            </div>
            <div>
              <span className="font-semibold">URL:</span>{" "}
              <button onClick={() => window.open(selectedIssue.url, '_blank')}>
                Open
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};


const getUrl = (type: string, id: string) => {
  const typeToPathMap: { [key: string]: string } = {
    "notes": "/vault/notes",
    "guides": "/vault/guides", 
    "usmle-guides": "/vault/usmle-guides",
    "essentials": "/vault/essentials",
    "cheatsheets": "/vault/cheatsheets", 
    "shortlistings": "/vault/shortlistings"
  };

  const basePath = typeToPathMap[type] || `/vault/${type}`;
  return `${basePath}/${id}`;
};
export default VaultReportedIssues;
