import { apiRequest } from '(apis)/api-interface/api-handler';
import { message, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

interface ActivatedSubscription {
    UserID: {
        username: string;
    };
}


interface FormState {
    Name: string;
    Date: string;
    Time: string;
    Description: string;
}

const EmailForm: React.FC<{ courseData: any }> = ({ courseData }) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [form, setForm] = useState<FormState>({
        Name: "",
        Date: "",
        Time: "",
        Description: ""
    });


    const [Emails, setEmails] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const GetStudents = async () => {
        setLoading(true);
        try {
            const Response = await apiRequest("getCourseStudents", null, [courseData._id]);
            if (Response.status === 200) {
                const emails = Response.data.students.map((student: any) => student.username)
                const filteredEmails = emails.filter((email: string) => email !== null && email !== undefined && email !== "")
                setEmails(filteredEmails) 
            } else {
                toast.error(Response.data.message)
            }
        } catch (error) {
            console.log(error)
            toast.error('An error occured while fetching students')
        }
        setLoading(false);
    }

    React.useEffect(() => {
        GetStudents();
    }, []);

    const SendEmail = async () => {
        setDisabled(true);

        if (form.Name === "" || form.Date === "" || form.Time === "" || form.Description === "") {
            notification.error({
                message: 'Error',
                description: 'Please fill all fields'
            });
            setDisabled(false);
            return;
        }

        // const Emails = activatedSubscriptions.map(sub => sub.UserID.username);

        const convertTo12HourFormat = (time: string) => {
            return dayjs(time, 'HH:mm').format('h:mm A');
        };

        const date = new Date(form.Date);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const time = convertTo12HourFormat(form.Time);

        const EmailData = {
            Emails,
            Name: form.Name,
            Course: courseData.courseName,
            Date: `${day} ${month} ${year}`,
            Time: time,
            Description: form.Description
        };

        try {
            const Response = await apiRequest("SendUpcomingAnnouncement",EmailData);
            if (Response.data.succes) {
            message.success(Response.data.message);
            }
        } catch (error) {
            message.error(error.response.data.message || error.message);
        }

        setForm({
            Name: "",
            Date: "",
            Time: "",
            Description: ""
        });
        setDisabled(false);
    };

    return (
        <div>
            <div className='mt-8'>
                <div className='flex flex-col'>
                    <h1 className='text-2xl font-bold'>Send Upcoming Class Emails</h1>
                    <div className='mt-4'>
                        <input
                            type="text"
                            placeholder='Topic Name'
                            className='w-full p-2 rounded border border-gray-300'
                            value={form.Name}
                            onChange={(e) => setForm({ ...form, Name: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder='Course'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={courseData.courseName}
                            disabled
                        />
                        <input
                            type="date"
                            placeholder='Date'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Date}
                            onChange={(e) => setForm({ ...form, Date: e.target.value })}
                        />
                        <input
                            type="time"
                            placeholder='Time'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Time}
                            onChange={(e) => setForm({ ...form, Time: e.target.value })}
                        />
                        <textarea
                            placeholder='Description'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Description}
                            onChange={(e) => setForm({ ...form, Description: e.target.value })}
                        />
                    </div>
                    <div className='mt-4'>
                        <div className="flex justify-end items-end">
                            <button
                                className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-rose-400 disabled:opacity-50'
                                disabled={disabled || loading}
                                onClick={SendEmail}
                            >
                                {disabled ? "Sending..." : "Send Email"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailForm;
